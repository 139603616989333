import React from "react";

// MUI
import { Slider } from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Colors
import { lightBlue, orange, green } from "@material-ui/core/colors";

const increaseRoot = makeStyles(theme => ({
  "@keyframes colorAnimation": {
    from: {
      color: "inherit"
    },
    to: {
      color: orange[500]
    }
  },
  root: {
    animation: "$colorAnimation 1s ease",
    animationFillMode: "forwards",
    height: 8,
    width: "100%"
  }
}));

const decreaseRoot = makeStyles(theme => ({
  "@keyframes colorAnimation": {
    from: {
      color: green[500]
    },
    to: {
      color: lightBlue[500]
    }
  },
  root: {
    animation: "$colorAnimation 1s ease",
    animationFillMode: "forwards",
    height: 8,
    width: "100%"
  }
}));

const useStyles = makeStyles(theme => ({
  "@keyframes colorAnimation": {
    from: {
      color: "inherit"
    },
    to: {
      color: green[500]
    }
  },
  root: {
    animation: "$colorAnimation 1s ease",
    animationFillMode: "forwards",
    height: 8,
    width: "100%"
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
}));

const NewTempSlider = ({
  min,
  max,
  value,
  setNewValue,
  increasing,
  decreasing
}) => {
  const classes = useStyles();
  const increaseClasses = increaseRoot();
  const decreaseClasses = decreaseRoot();

  const [sliderValue, setSlidervalue] = React.useState(value);

  const handleChange = (e, val) => {
    setSlidervalue(val);
    setNewValue(val);
  };

  return (
    <div className={classes.root}>
      <Slider
        min={min}
        max={max}
        onChange={handleChange}
        value={sliderValue}
        classes={{
          root: increasing
            ? increaseClasses.root
            : decreasing
            ? decreaseClasses.root
            : classes.root,
          thumb: classes.thumb,
          active: classes.active,
          valueLabel: classes.valueLabel,
          track: classes.track,
          rail: classes.rail
        }}
        valueLabelDisplay="auto"
        aria-label="slider"
      />
    </div>
  );
};

export default NewTempSlider;
