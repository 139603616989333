import React from "react";
import {
  CssBaseline,
  Container,
  List,
  ListItem,
  Divider,
  Typography
} from "@material-ui/core";

import Searchbar from "../components/Searchbar";
import NewAccessStepper from "../components/NewDashboard/NewAccessStepper";
import DeviceDialog from "../components/DeviceDialog/DeviceDialog";

const NewDeviceAccess = ({
  parentProps: { api, client, site, fetchBuildings, profile },
  values,
  handleReset,
  buildingData,
  setBuildingData,
  fetching,
  setFetching,
  handleBack,
  handleNext,
  loadOptions
}) => {
  const { activeStep } = values;

  //   Load new data automatically as site updates
  React.useEffect(() => {
    if (api && client && site && !fetching) {
      setFetching(true);
      const data = fetchBuildings({ route: "buildings", api });
      data.then(res => {
        setFetching(false);
        const data = JSON.parse(res.data);
        const filteredData = data.filter(building => building.siteId === site);
        setBuildingData(filteredData);
      });
    }

    // eslint-disable-next-line
  }, [site]);

  //   Return empty if no building data
  if (!buildingData.length) {
    return <span />;
  }

  //   Return Linear Progress on fetch
  if (fetching) {
    return null;
  }

  const suggestions = activeStep => {
    switch (activeStep) {
      case 0: {
        return buildingData;
      }
      case 1: {
        return values.building.floors;
      }
      case 2: {
        return values.floor.spaces;
      }
      case 3: {
        return values.space.devices;
      }
      case 4: {
        return values.device.points;
      }
      default: {
        return [];
      }
    }
  };

  if (buildingData && !fetching) {
    return (
      <>
        <CssBaseline />
        <Searchbar
          activeStep={activeStep}
          action={handleNext}
          data={suggestions(activeStep)}
        />
        <Container maxWidth="md" style={{ padding: "0 0 2rem 0" }}>
          <NewAccessStepper
            activeStep={activeStep}
            values={values}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
          />
          <Divider />
          <List>
            {activeStep === 0 ? (
              <ListItem>
                <Typography variant="h4">Building Options</Typography>
              </ListItem>
            ) : activeStep === 1 ? (
              <ListItem>
                <Typography variant="h4">Floor Options</Typography>
              </ListItem>
            ) : activeStep === 2 ? (
              <ListItem>
                <Typography variant="h4">Space Options</Typography>
              </ListItem>
            ) : activeStep === 3 ? (
              <ListItem>
                <Typography variant="h4">Device Options</Typography>
              </ListItem>
            ) : (
              <ListItem>
                <Typography variant="h4">Point Options</Typography>
              </ListItem>
            )}
            <Divider />
          </List>
          <List>
            {activeStep === 0 ? (
              loadOptions("buildings")
            ) : activeStep === 1 ? (
              loadOptions("floors")
            ) : activeStep === 2 ? (
              loadOptions("spaces")
            ) : activeStep === 3 ? (
              loadOptions("devices")
            ) : values.device.points ? (
              <DeviceDialog selectedDevice={values.device} profile={profile} />
            ) : // compact(
            //     orderBy(
            //       values.device.points,
            //       ["readOnly", "name"],
            //       ["asc"]
            //     ).map((point, idx) => {
            //       return (
            //         <div key={`${point.id}__${idx}`}>
            //           <ListItem
            //             style={{
            //               display: "flex",
            //               flexDirection: "column"
            //             }}
            //           >
            //             <Point {...point} />
            //           </ListItem>
            //         </div>
            //       );
            //     })
            //   )
            null}
          </List>
        </Container>
      </>
    );
  }

  return <span />;
};

export default NewDeviceAccess;
