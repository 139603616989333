import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { groupBy, isEmpty, orderBy } from "lodash";

//Event components
import Calendar from "react-calendar";
import VirtualList from "react-tiny-virtual-list";

//MUI
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
//HOCS
import { withStyles } from "@material-ui/core/styles";

const CalendarPoint = props => {
  const [date, setDate] = useState(new Date());
  const [index, setIndex] = useState(0);
  const { classes, name, data } = props;
  const { current, upcoming } = data;

  if (isEmpty(data)) return <span />;

  if (isEmpty(current) && !Boolean(upcoming.length)) {
    return (
      <Container maxWidth="sm" className={classes.border}>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          There are no current or upcoming events for {name}
        </Typography>
      </Container>
    );
  }

  const formatDate = dateString => {
    let date = dateString.split("-");
    return new Date(`${date[0]}-${date[1]}-${date[2]}T${date[3]}`);
  };

  const formatTime = timeString => {
    let t = timeString.split(":");
    return `${t[0]}:${t[1]} ${t[2] ? t[2].slice(-2) : ""}`;
  };

  const generateData = upcoming => {
    const eventArray = upcoming.map(date => {
      let start = formatDate(date.timeStart);
      let end = formatDate(date.timeEnd);

      return {
        name: date.name,
        displayDate: start.toDateString(),
        timeStart: start,
        timeEnd: end,
        status: date.status,
        contact: date.contact.name
      };
    });

    const group = groupBy(eventArray, d => d.displayDate);

    const orderedDates = [];
    Object.keys(group)
      .sort((a, b) => {
        return new Date(a) - new Date(b);
      })
      .forEach(key => {
        orderedDates.push(orderBy(group[key], ["timeStart"], ["asc"]));
      });

    return orderedDates;
  };

  const upcomingData = generateData(upcoming);

  const handleIndexChange = date => {
    const display = date.toDateString();

    upcomingData.forEach((datum, idx) => {
      if (datum[0].displayDate === display) {
        setIndex(idx);
      }
    });

    return;
  };

  const handleChange = date => {
    setDate(date);
    handleIndexChange(date);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Typography
        variant="h6"
        style={{ fontVariant: "all-petite-caps", textAlign: "center" }}>
        {name}
      </Typography>
      <Grid item md={12} className={classes.centered}>
        <Calendar
          onChange={handleChange}
          minDate={new Date()}
          value={date}
          className={classes.calendar}
          tileContent={({ date, view }) => {
            const displayWeek = date.toDateString();
            const displayMonth = date.getMonth();

            if (view === "month") {
              let events = "";
              upcomingData.forEach((datum, idx) => {
                if (datum[0].displayDate === displayWeek) {
                  events = datum.length;
                }
              });

              if (events) {
                return <span className={classes.borderBottom} />;
              }
            } else if (view === "year") {
              let events = 0;
              upcomingData.forEach((datum, idx) => {
                if (datum[0].timeStart.getMonth() === displayMonth) {
                  events += datum.length;
                }
              });

              if (events) {
                return (
                  <Badge badgeContent={events} color="secondary">
                    <span />
                  </Badge>
                );
              }
            }
          }}
          tileClassName={({ date, view }) => {
            const display = date.toDateString();

            if (view === "month") {
              let isOccupied = false;
              upcomingData.forEach((datum, idx) => {
                if (datum[0].displayDate === display) {
                  isOccupied = true;
                }
              });

              if (isOccupied) {
                return classNames(classes.tile, classes.occupied);
              } else {
                return classes.tile;
              }
            }
          }}
        />
      </Grid>

      <Grid item md={12}>
        <VirtualList
          style={{ margin: "1rem auto" }}
          width="350px"
          height={500}
          itemCount={upcomingData.length}
          itemSize={idx => upcomingData[idx].length * 225}
          stickyIndices={Object.keys(upcomingData).map((a, idx) => idx)}
          scrollToIndex={index}
          renderItem={({ index, style }) => {
            return (
              <Container
                className={classes.vlistContainer}
                maxWidth="xs"
                key={`__${index}_`}
                style={{
                  ...style
                }}>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1rem",
                    // background: gradients[index % gradients.length],
                    textAlign: "center",
                    padding: "1rem"
                  }}>
                  {upcomingData[index][0].displayDate}
                </Typography>
                <Grid
                  container
                  style={{
                    background: "white",
                    textAlign: "left"
                  }}>
                  {upcomingData[index].map((e, idx) => {
                    return (
                      <Grid
                        container
                        key={`${e.timeStart}_${idx}__${e.name}`}
                        style={{ marginBottom: "2rem" }}>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                          }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "column",
                              justifyContent: "center",
                              height: "100%",
                              alignItems: "center",
                              padding: ".5rem"
                            }}>
                            <Typography
                              variant="h6"
                              style={{ fontSize: ".875rem" }}>
                              {formatTime(e.timeStart.toLocaleTimeString())}
                            </Typography>
                            <KeyboardArrowDownIcon />
                            <Typography
                              variant="h6"
                              style={{ fontSize: ".875rem" }}>
                              {formatTime(e.timeEnd.toLocaleTimeString())}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          key={`${e.timeStart}_${idx}__${e.name}`}
                          style={{
                            height: "100%",
                            padding: "0 1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                          }}>
                          <Typography
                            variant="h6"
                            style={{ textAlign: "right" }}>
                            {e.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            style={{ fontSize: ".875rem", textAlign: "right" }}>
                            {e.contact}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            );
          }}
        />
      </Grid>
    </Container>
  );
};

CalendarPoint.propTypes = {
  date: PropTypes.object,
  upcoming: PropTypes.array
};

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "2rem"
  },
  centered: {
    display: "flex",
    margin: "0 auto"
  },
  calendar: {
    border: "none",
    maxHeight: "350px"
  },
  tile: {
    position: "relative",
    color: "black"
  },
  borderBottom: {
    display: "flex",
    height: 5,
    margin: "0 auto",
    padding: ".1rem",
    borderBottom: `5px solid ${theme.palette.secondary.main}`
  },
  occupied: {},
  border: {
    padding: "0 2rem",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "5px",
    marginBottom: ".5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 2rem"
    }
  },
  vlistContainer: {
    boxShadow: theme.shadows[8],
    margin: "0 auto",
    background: "white",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 .5rem"
    }
  }
});

export default withStyles(styles)(CalendarPoint);
