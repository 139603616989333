import React from "react";

// Firebase
import { functions } from "../../firebase/firebase";

// Tree
import { Tree } from "antd";
import "antd/dist/antd.css";

// Util
import { orderBy } from "lodash";

const { TreeNode } = Tree;

// Get Devices
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

const getBuilding = ({ id, api }) => {
  return fetchEndpoint({ api, route: `buildings/${id}` })
    .then(res => JSON.parse(res.data))
    .catch(e => {
      console.log("Error caught: ", e);
      return e;
    });
};

// const getParentKey = (key, tree) => {
//   let parentKey;
//   console.log("Key: ", key, " ", "Tree: ", tree);
//   for (let i = 0; i < tree.length; i++) {
//     const node = tree[i];

//     if (node.children) {
//       if (node.children.some(item => item.key === key)) {
//         parentKey = node.key;
//       } else if (getParentKey(key, node.children)) {
//         parentKey = getParentKey(key, node.children);
//       }
//     }
//   }

//   return parentKey;
// };

const generateTree = data => {
  return data.map(d => {
    const { client } = d;
    // Build tree
    const children = client.sites.map(s => {
      // Building
      const grandChildren = s.buildings.map(b => {
        return {
          key: b.id,
          title: b.name,
          type: "building",
          children: [],
          // Seeded payload
          client: client.id,
          clientName: client.name,
          site: s.id,
          siteName: s.name,
          buildingName: b.name
        };
      });

      // Site
      return {
        key: s.id,
        title: s.name,
        type: "site",
        children: grandChildren
      };
    });

    // Client
    return {
      key: client.id,
      title: client.name,
      type: "client",
      children: children
    };
  });
};

const SearchableTree = ({
  api,
  data,
  devices,
  addDevice,
  selectedDevices,
  setSelectedDevices,
  checkedKeys,
  variant = "addDevice"
}) => {
  const [values, setValues] = React.useState({
    expandedKeys: [],
    searchValue: "",
    autoExpandParent: false
  });
  const { searchValue, expandedKeys, autoExpandParent } = values;

  const [treeData, setTreeData] = React.useState(generateTree(data));

  const onExpand = (expandedKeys, treeNode) => {
    setValues({
      ...values,
      expandedKeys,
      autoExpandParent: false
    });
  };

  // const onChange = e => {
  //   const { value } = e.target;

  //   const expKeys = treeData
  //     .map(item => {
  //       return getParentKey(item.key, treeData);
  //     })
  //     .filter((item, i, self) => {
  //       return item && self.indexOf(item) === i;
  //     });

  //   setValues({
  //     ...values,
  //     expandedKeys: expKeys,
  //     searchValue: value ? value : "",
  //     autoExpandParent: true
  //   });
  //   // setTreeData([...treeData]);
  // };

  const onLoadData = treeNode => {
    return new Promise(resolve => {
      if (treeNode.props.dataRef.type !== "building") {
        resolve();
        return;
      }

      const id = treeNode.props.dataRef.key;

      const building = getBuilding({ api, id })
        .then(res => {
          const building = res.filter(b => b.id === id);
          return (
            building.length &&
            building[0].floors.map(floor => {
              console.log(floor);
              // Generate Tree
              return {
                type: "floor",
                title: floor.name,
                key: floor.id,
                children: floor.spaces.map(space => {
                  return {
                    type: "space",
                    title: space.name,
                    key: space.id,
                    children: space.devices.map(device => {
                      return {
                        type: "device",
                        title: device.name,
                        key: device.id,
                        site: building[0].siteId,
                        buildingName: building[0].name,
                        building: id,
                        floor: floor.id,
                        floorName: floor.name,
                        space: space.id,
                        spaceName: space.name,
                        device: device.id,
                        deviceName: device.name,
                        points: device.points,
                        children: []
                      };
                    })
                  };
                })
              };
            })
          );
        })
        .catch(e => {
          console.log(e);
          return e;
        });

      const buildTree = building
        .then(tree => {
          treeNode.props.dataRef.children = [
            ...treeNode.props.dataRef.children,
            ...tree
          ];
          setTreeData([...treeData]);
        })
        .catch(e => {
          console.log(e);
          return e;
        });
      return buildTree.then(res => resolve());
    });
  };

  const onCheck = (checkedKeys, info) => {
    const checkedDevices = info.checkedNodes.map(node => {
      return { ...node.props.dataRef };
    });

    // console.log("INFO: ", info);
    // console.log("CHECKED KEYS: ", checkedKeys);
    // console.log(checkedDevices);
    setSelectedDevices(checkedDevices.filter(sD => sD.type === "device"));
  };

  const loop = data => {
    // Map over data
    const mappedData = data.map(item => {
      const index = item.title.indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: "#f50" }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      // Seed data from parents
      if (item.type === "building") {
        let buildingChildren = item.children.map(floor => {
          return {
            ...floor,
            children: floor.children.map(space => {
              return {
                ...space,
                children: space.children.map(device => {
                  return {
                    ...device,
                    client: item.client,
                    clientName: item.clientName,
                    site: item.site,
                    siteName: item.siteName,
                    points: device.points
                  };
                })
              };
            })
          };
        });

        item.children = buildingChildren;
      }

      return (
        <TreeNode
          // disableCheckbox={item.type !== "device" && item.type !== "space"}
          isLeaf={item.type === "device"}
          key={item.key}
          dataRef={item}
          title={title}
        >
          {loop(orderBy(item.children, ["title"]))}
        </TreeNode>
      );
    });

    return mappedData;
  };

  return (
    <div>
      <Tree
        checkedKeys={checkedKeys}
        checkable
        selectable={false}
        loadData={onLoadData}
        onCheck={onCheck}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
      >
        {loop(orderBy(treeData, ["title"]))}
      </Tree>
    </div>
  );
};

export default SearchableTree;
