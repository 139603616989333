import React, { Component } from "react";
import MaterialTable from "material-table";

// import * as moment from "moment";
import { functions } from "../../firebase/firebase";

import Grid from "@material-ui/core/Grid";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Notifications from "@material-ui/icons/Notifications";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { withStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Typography } from "@material-ui/core";

import { find, isEmpty } from "lodash";

import HistoryChart from "./HistoryChart";

class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      updating: false,
      start: props.start,
      end: props.end,
      fetching: false
    };
  }

  render() {
    const { api, data, title, start, end, role } = this.props;
    const { updating } = this.state;

    const getHistory = functions.httpsCallable("getHistory");

    console.log(this.state.chartData);
    console.log(start, end);

    if (isEmpty(data)) {
      return <LinearProgress />;
    }

    const setChartData = data => {
      this.setState({ chartData: data });
    };

    let handleGetHistory = deviceId => {
      // let start = new Date(moment(new Date()).subtract(3, "months")).getTime();
      // let end = new Date().getTime();

      this.setState({ fetching: true });
      if (start || end) {
        getHistory({
          role,
          api,
          id: deviceId,
          type: "device",
          start: start
            ? Math.floor(new Date(start).getTime() / 1000)
            : Math.floor(new Date().getTime() / 1000),
          end: end
            ? Math.floor(new Date(end).getTime() / 1000)
            : Math.floor(new Date().getTime() / 1000)
        }).then(res => {
          this.setState({ fetching: false, chartData: res.data });
          if (res.data) {
            setChartData(res.data);
          } else {
            console.log(res);
          }
        });
      } else if (!start && !end) {
        getHistory({
          role,
          api,
          id: deviceId,
          type: "device"
          // start: String(start),
          // end: String(end)
        }).then(res => {
          this.setState({ fetching: false, chartData: res.data });
          if (res.data) {
            setChartData(res.data);
          } else {
            console.log(res);
          }
        });
      }
    };

    let tableData = data.devices.filter(d => {
      return (
        d.type === "hvac" &&
        (d.name.includes("VAV") ||
          d.name.includes("AHU") ||
          d.name.includes("Fan"))
      );
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          {updating && <LinearProgress />}
          <MaterialTable
            data={
              tableData
                ? tableData
                : [
                    {
                      device: "",
                      space: "",
                      floor: ""
                    }
                  ]
            }
            options={{
              filtering: true,
              exportButton: true,
              exportAllData: true
              // detailPanelType: "single"
            }}
            components={{
              Container: props => (
                <Paper elevation={0} square>
                  {props.children}
                </Paper>
              )
            }}
            columns={[
              {
                defaultSort: "asc",
                title: "Device",
                field: "name"
              },
              {
                title: "Space",
                field: "spaceId",
                render: rowData => {
                  const spaceName = data.spaces.filter(s => {
                    return s.id === rowData.spaceId;
                  });
                  return <span>{spaceName[0].name}</span>;
                }
              },
              {
                title: "Floor",
                field: "floorName",
                render: rowData => {
                  const floorName = data.floors.filter(f => {
                    return f.id === rowData.floorId;
                  });
                  return <span>{floorName[0].name}</span>;
                }
              }
            ]}
            title={title}
            onRowClick={(event, rowData, togglePanel) => {
              this.setState({ chartData: [] });
              togglePanel();
              handleGetHistory(rowData.id);
            }}
            detailPanel={[
              {
                render: rowData => {
                  if (this.state.fetching) return <LinearProgress />;
                  if (
                    !this.state.chartData ||
                    (!this.state.chartData.length && !this.state.fetching)
                  ) {
                    handleGetHistory(rowData.id);
                  }
                  return (
                    <Grid container>
                      {this.state.chartData
                        .filter(cd => cd.data.length)
                        .map(data => {
                          let point = find(
                            rowData.points,
                            p => p.id === data.pointId
                          );
                          if (!rowData || !point) return <span />;
                          return (
                            <Grid item xs={12} key={data.pointId}>
                              <Typography variant="h6" align="center">
                                {rowData.name}: {point.name}
                              </Typography>
                              <HistoryChart
                                name={rowData.name}
                                key={data.pointId}
                                data={data.data}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
                }
              }
            ]}
            icons={{
              Add: AddBox,
              Check: Check,
              Clear: Clear,
              Delete: Notifications,
              DetailPanel: ChevronRight,
              Edit: Edit,
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search: Search,
              SortArrow: ArrowUpward,
              ThirdStateCheck: Remove,
              ViewColumn: ViewColumn
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  bar: {
    backgroundColor: theme.palette.primary.main
  }
});

export default withStyles(styles)(HistoryTable);
