import React, { Component } from "react";
import PropTypes from "prop-types";

import * as ACTIONS from "../../actions";

import { compose } from "redux";
import { connect } from "react-redux";

//MUI
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//HOCs
import { withStyles } from "@material-ui/core/styles";

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        background: `linear-gradient(90deg, #93a5cf 0%, #e4efe9 100%)`,
        borderColor: theme.palette.common.white
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

class NewVFDSwitch extends Component {
  constructor(props) {
    super();
    this.state = {
      values: props.data.value,
      checkedA: props.data.value,
      updating: false,
      ready: false
    };
  }

  componentDidMount() {
    const client = new WebSocket(
      "wss://ptxrcj27wa.execute-api.us-east-1.amazonaws.com/v1"
    );

    client.onopen = evt => {
      this.setState({ ready: true });
    };

    client.onclose = evt => {
      this.setState({ ready: false });
    };

    client.onerror = evt => {
      client.close();
    };

    this.socket = client;
  }

  componentDidUpdate() {
    if (this.state.values === this.props.data.value && this.state.updating) {
      this.setState({ updating: false });
    }
  }

  onChange = name => event => {
    this.setState({ updating: true });
    this.props.updateValue(
      this.props.api,
      "points",
      this.props.id,
      event.target.checked,
      this.props.role
    );

    if (this.state.ready) {
      this.socket.send(
        JSON.stringify({
          action: "subscribe",
          devices: [this.props.deviceId]
        })
      );

      this.socket.onmessage = evt => {
        if (evt.data) {
          const data = JSON.parse(evt.data);
          if (data.data) {
            if (data.id === this.props.id) {
              this.props.getData(
                this.props.api,
                "buildings",
                null,
                "buildings"
              );
            }
          }
        }
      };
    }

    this.setState({ [name]: event.target.checked });
    if (this.props.handleUpdate) {
      this.props.handleUpdate(this.props.id);
    }
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <FormGroup>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Off</Grid>
              <Grid item>
                <StyledSwitch
                  checked={this.state.checkedA}
                  onChange={this.onChange("checkedA")}
                  value="values"
                />
              </Grid>
              <Grid item>On</Grid>
            </Grid>
          </Typography>
        </FormGroup>
      </div>
    );
  }
}

NewVFDSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default compose(
  connect(
    state => ({
      api: state.firebase.profile.api,
      role: state.firebase.profile.role
    }),
    dispatch => ({
      updateAspect: (aspect, payload) =>
        dispatch({
          type: ACTIONS.UPDATE_DATA_ASPECT,
          aspect,
          payload
        }),
      updateValue: (api, route, id, value, role) =>
        dispatch({
          api,
          type: ACTIONS.POST_SAGA,
          route,
          id,
          value,
          role
        }),
      getData: (api, filter, params, storeAs, type) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs, type }
        })
    })
  )
)(NewVFDSwitch);
