import React, { Component } from "react";
import PropTypes from "prop-types";

import firebase from "firebase";
import { db } from "../../firebase/firebase";

//Uploader
import FileUploader from "react-firebase-file-uploader";

// Icons
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { indigo } from "@material-ui/core/colors";
import { CircularProgress, Typography } from "@material-ui/core";

class Uploader extends Component {
  state = {
    isUploading: false,
    filesToGo: 0,
    uploadSuccessful: false,
    max: 100
  };

  imageSize = url => {
    return new Promise((res, rej) => {
      let img = new Image();
      img.onload = () => {
        res({ url, width: img.width, height: img.height });
      };

      img.onerror = () => {
        rej({ url, width: 0, height: 0 });
      };

      img.src = url;
    });
  };

  // Handlers
  handleUploadStart = (progress, task) => {
    return this.setState({
      isUploading: true,
      filesToGo: this.state.filesToGo + 1,
      uploadSuccessful: false
    });
  };
  handleUploadError = (error, task) => {
    this.setState({ isUploading: false });
  };
  handleUploadSuccess = ({ storageRef, dbPath }) => (filename, task) => {
    const fileRef = firebase
      .storage()
      .ref(storageRef)
      .child(filename);

    let imageUrl = fileRef
      .getDownloadURL()
      .then(url => url)
      .catch(e => {
        console.log(e);
        return e;
      });

    let imageSize = imageUrl.then(url => {
      return this.imageSize(url)
        .then(res => res)
        .catch(e => {
          console.log(e);
          return e;
        });
    });

    imageSize
      .then(res => {
        const payload = {
          file: filename,
          lastAccessed: new Date(),
          thumbs: {},
          uploadDate: new Date(),
          url: res.url,
          width: res.width,
          height: res.height
        };

        const imagesRef = db.ref(dbPath).update(payload);

        imagesRef.then(res => {
          this.setState({
            filesToGo: this.state.filesToGo - 1
          });

          if (this.state.filesToGo <= 0) {
            this.setState({
              isUploading: false,
              filesToGo: 0,
              url: "",
              uploadSuccessful: false
            });
          }
          return { filename };
        });
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  };

  render() {
    const { storageRef, dbPath } = this.props;
    const { progress, max } = this.state;

    const normalise = value => ((value - 0) * 100) / (max - 0);

    return (
      <form style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <label
          onClick={() => this.setState({ uploadSuccessful: false })}
          style={{
            position: "relative",
            display: "flex",
            alignItem: "center"
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: "1rem",
              border: `5px dashed ${indigo[200]}`,
              borderRadius: "100%"
            }}
          >
            {this.state.isUploading ? (
              <div style={{ position: "relative" }}>
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    fontSize: "2rem",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {this.state.filesToGo}
                </Typography>
                <CircularProgress
                  size="5rem"
                  style={{ color: indigo[200] }}
                  value={normalise(progress)}
                />
              </div>
            ) : (
              <AddPhotoAlternateIcon
                style={{ fontSize: "5rem", color: indigo[200] }}
              />
            )}
          </div>
          <FileUploader
            hidden
            multiple
            accept="image/*"
            name="filename"
            randomizeFilename
            storageRef={firebase.storage().ref(storageRef)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess({
              storageRef,
              dbPath
            })}
          />
        </label>
      </form>
    );
  }
}

Uploader.propTypes = {
  text: PropTypes.string.isRequired,
  border: PropTypes.bool
};

export default Uploader;
