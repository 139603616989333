import React from "react";
import uuid from "uuid";

// Firebase
import * as firebase from "firebase";
import { db, functions, store } from "../../../firebase/firebase";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";

// MUI
import {
  AppBar,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  Input,
  Collapse
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import {
  AccountCircle,
  Close,
  Edit,
  ExpandMore,
  Security,
  Visibility,
  Replay,
  VisibilityOff,
  Clear
} from "@material-ui/icons";

// Util
import clsx from "clsx";
import { find, orderBy } from "lodash";
import { useSnackbar } from "notistack";

// Components
import UserNew from "./UserNew";

// Permissions Map
const PERMISSIONS = [
  {
    value: "read",
    text: "read",
    icon: <Visibility style={{ marginRight: ".5rem" }} />
  },
  {
    value: "write",
    text: "write",
    icon: <Edit style={{ marginRight: ".5rem" }} />
  },
  {
    value: "read write",
    text: "both",
    icon: <Security style={{ marginRight: ".5rem" }} />
  }
];

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    color: "white"
  },
  title: {
    color: theme.palette.getContrastText(theme.palette.text.primary),
    marginLeft: theme.spacing(2),
    flex: 1
  },
  textField: {
    margin: "0 1rem",
    width: 200
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    minWidth: 200,
    margin: "0 1rem"
  },
  selectEmpty: {},
  fullWidthMobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  accordion: {
    width: "100%"
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  accordionSecondaryHeading: {
    display: "flex",
    minWidth: 65,
    marginLeft: "1rem",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const postPermissions = functions.httpsCallable("postPermissions");

const AddUserNew = ({
  id,
  group,
  groups,
  open,
  setOpen,
  auth,
  profile,
  data,
  selection,
  currentMember
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [adding, setAdding] = React.useState(false);
  const defaultValues = {
    id: uuid.v4(),
    first: "",
    last: "",
    email: "",
    phone: "",
    role: "",
    roleObject: {},
    point: {},
    permissions: []
  };
  const [values, setValues] = React.useState(defaultValues);
  const { first, last, email, phone, role } = values;

  const [password, setPassword] = React.useState("TapaDemo");
  const [showPassword, setShowPassword] = React.useState(true);

  const [didSetCustom, setDidSetCustom] = React.useState(false);
  const [expanded, setExpanded] = React.useState("");

  const [closeDialog, setCloseDialog] = React.useState(false);

  const [permissionObject, setPermissionObject] = React.useState({
    devices: []
  });

  const [updating, setUpdating] = React.useState(false);
  const [warnDelete, setWarnDelete] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState({
    api: "",
    name: "",
    email: ""
  });

  // Group Admin
  const [hasPermissionsAccess, setHasPermissionsAccess] = React.useState(false);

  // Additional Options
  const defaultGroupOptions = {
    canCreateGroup: false,
    canAddUsers: false,
    canEditThisGroup: false,
    // Edit Group Options
    canEditWhitelabel: false,
    canAddDevice: false,
    canEditRole: false
  };
  const [groupOptions, setGroupOptions] = React.useState(defaultGroupOptions);
  const {
    canCreateGroup,
    canAddUsers,
    canEditThisGroup,
    canEditWhitelabel,
    canAddDevice,
    canEditRole
  } = groupOptions;

  // Edit Group Handler,
  const handleCanEditGroup = canEdit => {
    if (!canEdit) {
      setGroupOptions({
        ...groupOptions,
        canEditThisGroup: false,
        canEditWhitelabel: false,
        canAddDevice: false,
        canEditRole: false
      });
    } else {
      setGroupOptions({
        ...groupOptions,
        canEditThisGroup: true,
        canEditWhitelabel: true,
        canAddDevice: true,
        canEditRole: true
      });
    }
  };

  React.useEffect(() => {
    let selectedRole = find(group.roles, ["roleName", role]);

    if (selectedRole) {
      setPermissionObject(selectedRole);
    } else {
      setPermissionObject({ devices: [] });
    }
  }, [role, group.roles, group, groups]);

  const handleClose = () => {
    setValues(defaultValues);
    setPassword("TapaDemo");
    setShowPassword(true);
    setGroupOptions(defaultGroupOptions);
    setHasPermissionsAccess(false);
    setPermissionObject({ devices: [] });
    setDidSetCustom(false);
    setOpen(false);
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const addUser = () => {
    setAdding(true);
    const callGetApiKey = functions.httpsCallable("getApiKey");
    const getApiKey = callGetApiKey({ email }).then(res => {
      const { data: { apiKey } = {} } = res;
      return apiKey;
    });

    //Clone points
    const selectedPoints = permissionObject.selectedPoints.map(point => {
      return { permission: point.permission, ...point.ids };
    });

    const dbRoute = currentMember
      ? `users/${profile.parent}/groups/${profile.parentGroup}`
      : `users/${auth.uid}/groups/${id}`;

    const addUserToGroup = getApiKey.then(key => {
      return store
        .doc(dbRoute)
        .update({
          users: firebase.firestore.FieldValue.arrayUnion({
            ...values,
            permissions: selectedPoints,
            roleObject: permissionObject,
            status: "needsInvite",
            permissionsAccess: {
              hasPermissionsAccess,
              canCreateGroup,
              canAddUsers,
              canEditThisGroup,
              canEditWhitelabel,
              canAddDevice,
              canEditRole
            },
            apiKey: key,
            parent: auth.uid,
            parentGroup: id
          })
        })
        .then(res => {
          setAdding(false);
          setValues(defaultValues);
          setPassword("TapaDemo");
          setHasPermissionsAccess(false);
          setGroupOptions(defaultGroupOptions);
          return key;
        })
        .catch(e => {
          setAdding(false);
          console.log(e);
          return key;
        });
    });

    return addUserToGroup
      .then(key => {
        console.log(key);
        return postPermissions({
          api: key,
          permissions: selectedPoints
        }).then(res => {
          return res;
        });
      })
      .then(res => {
        enqueueSnackbar(`${values.first} ${values.last} Successfuly Added`, {
          variant: "success",
          autoHideDuration: 2000
        });
        // Cleanup
        setValues({
          id: uuid.v4(),
          first: "",
          last: "",
          email: "",
          phone: "",
          role: "",
          roleObject: {},
          point: {},
          permissions: []
        });
        return res;
      });
  };

  const [editing, setEditing] = React.useState(false);
  const handleEditUsers = user => {
    setEditing(true);
    const {
      id,
      first,
      last,
      email,
      phone,
      role,
      roleObject,
      point,
      permissions,
      permissionsAccess: {
        hasPermissionsAccess,
        canCreateGroup,
        canAddUsers,
        canEditThisGroup,
        canEditWhitelabel,
        canAddDevice,
        canEditRole
      }
    } = user;
    setValues({
      id,
      first,
      last,
      email,
      phone,
      role,
      roleObject,
      point,
      permissions
    });

    setGroupOptions({
      canCreateGroup,
      canAddUsers,
      canEditThisGroup,
      canEditWhitelabel,
      canAddDevice,
      canEditRole
    });

    setHasPermissionsAccess(hasPermissionsAccess);
  };

  const handleCancelEditing = () => {
    setValues(defaultValues);
    setPassword("TapaDemo");
    setShowPassword(true);
    setGroupOptions(defaultGroupOptions);
    setHasPermissionsAccess(false);
    setPermissionObject({ devices: [] });
    setDidSetCustom(false);
    setEditing(false);
  };

  const handleUpdateUser = () => {
    setUpdating(true);
    const updatedUsers = group.users.filter(u => u.id !== values.id);

    const dbRoute = currentMember
      ? `users/${profile.parent}/groups/${profile.parentGroup}`
      : `users/${auth.uid}/groups/${id}`;

    const usersRef = store.doc(dbRoute);
    const removeFromGroup = usersRef
      .update({
        users: updatedUsers
      })
      .catch(e => {
        console.log(`Error Removing from DB: ${e}`);
        handleDeleteExit();
        setUpdating(false);
        return e;
      });

    removeFromGroup.then(res => {
      addUser().then(res => {
        setUpdating(false);
        handleCancelEditing();
      });
    });
  };

  // Delete Users

  // Reject Deletion
  const handleDeleteExit = () => {
    setDeleteUser({
      api: "",
      name: "",
      email: ""
    });
    setWarnDelete(false);
    setUpdating(false);
  };

  // Confirm Deletion
  const handleDeleteUser = () => {
    // NB: handleDeleteExit contains setUpdating(false)
    setUpdating(true);

    // Setup
    const deleteUserAuth = functions.httpsCallable("deleteUser");
    const updatedUsers = group.users.filter(u => u.email !== deleteUser.email);
    const usersRef = store.doc(`users/${auth.uid}/groups/${group.groupId}`);

    // Remove from Group
    const removeFromGroup = usersRef
      .update({
        users: updatedUsers
      })
      .catch(e => {
        console.log(`Error Removing from DB: ${e}`);
        handleDeleteExit();
        return e;
      });

    // Remove User From Auth
    const removeFromAuth = removeFromGroup
      .then(res => {
        return deleteUserAuth({ email: deleteUser.email });
      })
      .catch(e => {
        console.log(`Error calling AUTH, check functions: ${e}`);
        handleDeleteExit();
        return e;
      });

    // Cleanup
    const removeFromDB = removeFromAuth
      .then(res => {
        try {
          let ref = db.ref(`users/${res.data}`);
          return ref.remove();
        } catch {
          enqueueSnackbar(`${deleteUser.name} Successfuly Removed`, {
            variant: "success",
            autoHideDuration: 2000
          });
          handleDeleteExit();
          return res;
        }
      })
      .catch(e => {
        console.log(`Error Removing from AUTH: ${e}`);
        handleDeleteExit();
      });

    return removeFromDB
      .then(res => {
        enqueueSnackbar(`${deleteUser.name} Successfuly Removed`, {
          variant: "success",
          autoHideDuration: 2000
        });

        handleDeleteExit();
      })
      .catch(e => {
        console.log("Cleanup error: ", e);
        handleDeleteExit();

        return e;
      });
  };

  // Access
  const handleSave = () => {
    if (first || last) {
      return setCloseDialog(true);
    }

    enqueueSnackbar(`${group.name} Saved`, {
      variant: "success",
      autoHideDuration: 2000
    });
    handleClose();
  };

  const discardChanges = () => {
    setCloseDialog(false);
    handleClose();
  };

  const handleHasPermissionsAccess = val => {
    setHasPermissionsAccess(val);
    if (!val) {
      setGroupOptions({ ...defaultGroupOptions });
    }
  };

  const handleCanCreateGroup = val => {
    if (!val) {
      setGroupOptions({ ...defaultGroupOptions });
    } else {
      setGroupOptions({ ...groupOptions, canCreateGroup: val });
    }
  };

  const DeviceLabel = ({ names }) => {
    let { buildingName, floorName, spaceName, deviceName } = names;
    return (
      <Typography variant="body1" style={{ flexGrow: 1 }}>
        {`${buildingName} > ${floorName} > ${spaceName} > `}
        <b>{deviceName}</b>
      </Typography>
    );
  };

  const handlePermission = (device, newPoint, permission) => {
    // Update Devices
    let updatedDevices = permissionObject.devices.map(permissionDevice => {
      // If device matches
      if (permissionDevice.id === device.id) {
        let filteredDevicePoints = permissionDevice.points.map(devicePoint => {
          if (devicePoint.ids.pointId === newPoint.ids.pointId) {
            return {
              ...devicePoint,
              permission:
                permission.value === devicePoint.permission
                  ? ""
                  : permission.value
            };
          }
          return devicePoint;
        });

        return { ...device, points: filteredDevicePoints };
      }

      return permissionDevice;
    });

    const selectedPoints = permissionObject.selectedPoints.map(point => {
      if (point.ids.pointId === newPoint.ids.pointId) {
        return {
          ...newPoint,
          permission:
            permission.value === point.permission ? "" : permission.value
        };
      }
      return { ...point, permission: point.permission };
    });

    // Set object based on equality
    setPermissionObject({
      ...permissionObject,
      devices: updatedDevices,
      roleName: "Custom",
      selectedPoints
    });

    if (!didSetCustom) {
      setDidSetCustom(true);
    }
  };

  const handleExpand = id => {
    if (id === expanded) {
      return setExpanded("");
    }
    setExpanded(id);
  };

  const handleResetCustom = () => {
    setValues({ ...values, role: "" });
    setDidSetCustom(false);
  };

  // PASSWORD
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const CloseDialog = () => (
    <Dialog open={closeDialog} onClose={() => setCloseDialog(false)}>
      <DialogTitle id="alert-dialog-title">
        {"There are unsaved changes"}
      </DialogTitle>
      <IconButton
        onClick={() => setCloseDialog(false)}
        style={{ position: "absolute", top: 0, right: 0 }}
      >
        <Clear />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The user has not been added to the group. To add your user, click "Add
          User" below Group Management, if you want to go back to your groups,
          click "Discard Changes"
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => discardChanges()} color="primary" autoFocus>
          Discard Changes
        </Button>
      </DialogActions>
    </Dialog>
  );

  console.log(group, groups);

  return (
    <div>
      {/* Warn Close Without Saving */}
      <CloseDialog />

      {/* Warn Delete User Dialog */}
      <Dialog
        open={warnDelete}
        onClose={handleDeleteExit}
        TransitionComponent={Transition}
        disableBackdropClick={updating ? true : false}
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ wordBreak: "break-word" }}
          >
            Are you sure you want to remove {deleteUser.name} (
            {deleteUser.email}) from device permissions? This action is
            permanent.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={handleDeleteExit}
            color="primary"
            disabled={updating}
          >
            Exit
          </Button>
          {updating ? (
            <CircularProgress size={20} />
          ) : (
            <Button onClick={handleDeleteUser} color="primary" autoFocus>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* AddUserDialog */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          color={editing ? "secondary" : "primary"}
        >
          <Toolbar>
            {editing ? (
              <IconButton
                onClick={() => handleCancelEditing()}
                edge="start"
                color={"inherit"}
                aria-label="close"
              >
                <Clear />
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                color={"inherit"}
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            )}

            {editing ? (
              <Typography variant="h6" className={classes.title}>
                Editing User in {group.name}
              </Typography>
            ) : (
              <Typography variant="h6" className={classes.title}>
                Add Users to {group.name}
              </Typography>
            )}
            {editing ? (
              <Button
                color="inherit"
                onClick={() => handleUpdateUser()}
                disabled={updating}
              >
                {updating ? <CircularProgress size={20} /> : "update"}
              </Button>
            ) : (
              <Button
                color="inherit"
                onClick={() => handleSave()}
                disabled={updating}
              >
                save
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <List>
          <Collapse in={!editing}>
            {group.users &&
              orderBy(group.users, ["last"]).map(user => {
                return (
                  <UserNew
                    key={user.id}
                    auth={auth}
                    groupId={id}
                    user={user}
                    group={group}
                    profile={profile}
                    setDeleteUser={setDeleteUser}
                    setWarnDelete={setWarnDelete}
                    password={password}
                    handleEditUsers={handleEditUsers}
                  />
                );
              })}
            {group && group.users && group.users.length ? <Divider /> : null}
          </Collapse>
        </List>

        <List>
          {/* User Account Information */}
          <ListItem>
            <ListItemAvatar>
              <Avatar color="secondary">
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="User Account Information" />
          </ListItem>
          <ListItem>
            <TextField
              id="standard-first"
              label="First Name"
              className={classes.textField}
              value={first}
              onChange={handleChange("first")}
              margin="normal"
            />
            <TextField
              id="standard-last"
              label="Last Name"
              className={classes.textField}
              value={last}
              onChange={handleChange("last")}
              margin="normal"
            />
          </ListItem>

          <ListItem>
            <TextField
              id="standard-phone"
              label="Phone"
              className={classes.textField}
              value={phone}
              onChange={handleChange("phone")}
              margin="normal"
            />
            <span className={classes.textField} />
          </ListItem>

          <ListItem>
            <TextField
              id="standard-email"
              label="Email"
              className={classes.textField}
              value={email}
              onChange={handleChange("email")}
              margin="normal"
            />
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <Input
                disabled={true}
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disabled={true}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </ListItem>

          <ListItem style={{ display: "flex", alignItems: "center" }}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Role</InputLabel>
              <Select
                value={didSetCustom ? "Custom" : role}
                onChange={handleChange("role")}
                inputProps={{
                  name: "role",
                  id: "role-simple"
                }}
                disabled={didSetCustom}
              >
                {didSetCustom && (
                  <MenuItem key={"custom"} value={"Custom"}>
                    Custom
                  </MenuItem>
                )}
                {group.roles.map(role => {
                  return (
                    <MenuItem key={role.roleName} value={role.roleName}>
                      {role.roleName.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {didSetCustom && (
              <Button onClick={() => handleResetCustom()}>
                <Replay style={{ marginRight: ".5rem" }} /> Revert
              </Button>
            )}
          </ListItem>
          <ListItem
            style={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "baseline"
            }}
          >
            <ListItemText
              primary="Group Management"
              secondary="Control how this user interacts with permissions."
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasPermissionsAccess}
                    onChange={() =>
                      handleHasPermissionsAccess(!hasPermissionsAccess)
                    }
                    value={hasPermissionsAccess}
                  />
                }
                label="Has Permissions Access"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!hasPermissionsAccess}
                    checked={canCreateGroup}
                    onChange={() => handleCanCreateGroup(!canCreateGroup)}
                    value={canCreateGroup}
                  />
                }
                label="Can Create Group"
              />
            </FormGroup>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!hasPermissionsAccess}
                    checked={canAddUsers}
                    onChange={() =>
                      setGroupOptions({
                        ...groupOptions,
                        canAddUsers: !canAddUsers
                      })
                    }
                    value={canAddUsers}
                  />
                }
                label="Can Add Users"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!hasPermissionsAccess}
                    checked={canEditThisGroup}
                    onChange={() => handleCanEditGroup(!canEditThisGroup)}
                    value={canEditThisGroup}
                  />
                }
                label="Can Edit This Group"
              />
            </FormGroup>

            {/* Edit Group Options */}
            <Collapse in={canEditThisGroup}>
              <FormGroup row style={{ marginLeft: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!canEditThisGroup}
                      checked={canEditWhitelabel}
                      onChange={() =>
                        setGroupOptions({
                          ...groupOptions,
                          canEditWhitelabel: !canEditWhitelabel
                        })
                      }
                      value={canEditWhitelabel}
                    />
                  }
                  label="Can Edit Whitelabel"
                />
              </FormGroup>
              <FormGroup row style={{ marginLeft: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!canEditThisGroup}
                      checked={canAddDevice}
                      onChange={() =>
                        setGroupOptions({
                          ...groupOptions,
                          canAddDevice: !canAddDevice
                        })
                      }
                      value={canAddDevice}
                    />
                  }
                  label="Can Add Device"
                />
              </FormGroup>
              <FormGroup row style={{ marginLeft: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!canEditThisGroup}
                      checked={canEditRole}
                      onChange={() =>
                        setGroupOptions({
                          ...groupOptions,
                          canEditRole: !canEditRole
                        })
                      }
                      value={canEditRole}
                    />
                  }
                  label="Can Edit Role"
                />
              </FormGroup>
            </Collapse>
          </ListItem>
          <ListItem>
            {!editing && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => addUser()}
                style={{ margin: "1rem 2rem" }}
                disabled={!role || !first || !last || !email || adding}
              >
                {adding ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Add User"
                )}
              </Button>
            )}
          </ListItem>

          {/* Permissions */}
          <Divider />

          <ListItem style={{ marginTop: "1.5rem" }}>
            <ListItemAvatar>
              <Avatar color="secondary">
                <Security />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              style={{ paddingLeft: "2rem" }}
              primary="Custom Permissions"
              secondary="Tailor custom permissions for user"
            />
          </ListItem>

          <List style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {permissionObject.devices.length ? (
              <Typography
                variant="h3"
                style={{ padding: "1rem", width: "100%" }}
              >
                Devices
              </Typography>
            ) : null}
            {orderBy(permissionObject.devices, "name").map(device => (
              <ExpansionPanel
                key={device.id}
                style={{ width: "100%" }}
                expanded={expanded === device.id}
                onChange={() => handleExpand(device.id)}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <DeviceLabel names={device.points[0].names} />
                  <Typography className={classes.accordionSecondaryHeading}>
                    {device.points.length} Points
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ListItem
                    key={device.id}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "0 0 1.5rem 0"
                    }}
                  >
                    <List
                      style={{ display: "flex", flexWrap: "wrap", padding: 0 }}
                    >
                      <Divider />
                      {orderBy(device.points, ["name"]).map(point => {
                        return (
                          <ListItem
                            key={point.ids.pointId}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              padding: "0 0 1rem 0"
                            }}
                          >
                            <ListItemText
                              primary={point.names.pointName}
                              className={classes.fullWidthMobile}
                            />
                            {PERMISSIONS.map(permission => {
                              return (
                                <Button
                                  onClick={() =>
                                    handlePermission(device, point, permission)
                                  }
                                  key={permission.text}
                                  color={
                                    permission.value === point.permission
                                      ? "primary"
                                      : "inherit"
                                  }
                                  variant={
                                    permission.value === point.permission
                                      ? "contained"
                                      : "outlined"
                                  }
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: ".5rem"
                                  }}
                                  disabled={
                                    point.readOnly &&
                                    (permission.value === "read write" ||
                                      permission.value === "write")
                                  }
                                >
                                  {permission.icon}
                                  {permission.text}
                                </Button>
                              );
                            })}
                          </ListItem>
                        );
                      })}
                    </List>
                  </ListItem>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
            {/* 
            {orderBy(permissionObject.devices, "name").map(device => (
              <ListItem
                key={device.id}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  paddingBottom: "1.5rem"
                }}>
                <ListItemText
                  primary={<DeviceLabel names={device.points[0].names} />}
                  secondary={`${device.points.length} Points`}
                />
                <List style={{ display: "flex", flexWrap: "wrap" }}>
                  <Divider />
                  {orderBy(device.points, ["name"]).map(point => {
                    return (
                      <ListItem
                        key={point.ids.pointId}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "0 0 1rem 0"
                        }}>
                        <ListItemText
                          primary={point.names.pointName}
                          className={classes.fullWidthMobile}
                        />
                        {PERMISSIONS.map(permission => {
                          return (
                            <Button
                              onClick={() =>
                                handlePermission(device, point, permission)
                              }
                              key={permission.text}
                              color={
                                permission.value === point.permission
                                  ? "primary"
                                  : "inherit"
                              }
                              variant={
                                permission.value === point.permission
                                  ? "contained"
                                  : "outlined"
                              }
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: ".5rem"
                              }}
                              disabled={
                                point.readOnly &&
                                (permission.value === "read write" ||
                                  permission.value === "write")
                              }>
                              {permission.icon}
                              {permission.text}
                            </Button>
                          );
                        })}
                      </ListItem>
                    );
                  })}
                </List>
              </ListItem>
            ))} */}
          </List>
        </List>
      </Dialog>
    </div>
  );
};

export default compose(
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    ordered: state.firestore.ordered,
    selection: state.selection
  }))
)(AddUserNew);
