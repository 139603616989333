import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

// MUI
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

//MUI Icons
import {
  CheckCircle,
  Cancel,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";

// MUI Colors
import {
  green,
  deepOrange,
  lightBlue,
  blueGrey
} from "@material-ui/core/colors";

//HOCS
import { withStyles } from "@material-ui/core/styles";

import NewVFDSlider from "../NewSlider/NewVFDSlider";
import NewVFDSwitch from "../NewSlider/NewVFDSwitch";

const getStatusIcon = status => {
  switch (status) {
    case "updating": {
      return (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      );
    }

    case "normal": {
      return <CheckCircle style={{ color: green[400] }} />;
    }

    case "error": {
      return <Cancel style={{ color: deepOrange[400] }} />;
    }

    default: {
      return <CheckCircle />;
    }
  }
};

const VFD = props => {
  const { classes, id, name, data, readOnly, dataType } = props;

  const { displayString, unit, value, status } = data;
  const [newValue, setNewValue] = React.useState("");

  const [parentData, setParentData] = React.useState({
    unit: unit ? unit : "",
    displayString,
    status,
    value
  });

  React.useEffect(() => {
    setParentData({
      unit: unit ? unit : "",
      displayString,
      status,
      value
    });
  }, [unit, displayString, status, value]);

  const DisplayValues = ({ displayNew, increasing }) => (
    <span className={classes.secondaryDisplay}>
      {/* Show Original Value */}
      {parentData.displayString}

      {/* Show New Value if EXISTS or IS DIFFERENT */}
      {displayNew ? (
        <span className={classes.secondaryDisplay}>
          {increasing ? (
            <KeyboardArrowRight style={{ color: lightBlue[300] }} />
          ) : (
            <KeyboardArrowLeft style={{ color: blueGrey[800] }} />
          )}
          {
            <b
              style={{
                color: increasing ? lightBlue[300] : blueGrey[800]
              }}
            >
              {newValue} {parentData.unit ? parentData.unit : ""}
            </b>
          }
        </span>
      ) : null}
    </span>
  );

  if (readOnly) {
    return (
      <List disablePadding>
        <ListItem key={id} dense>
          <ListItemText primary={name} secondary={parentData.displayString} />
          <ListItemIcon className={classes.icon}>
            {getStatusIcon(status)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  }

  if (dataType && dataType === "boolean") {
    return (
      <List disablePadding>
        <ListItem key={id} dense>
          <ListItemText
            primary={name}
            secondary={data.value ? "On" : "Off"}
            className={classes.display}
          />
          <NewVFDSwitch
            id={id}
            deviceId={props.deviceId}
            api={props.api}
            data={data}
          />
          <ListItemIcon
            className={classes.icon}
            classes={{ root: classes.iconRoot }}
          >
            {getStatusIcon(status)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  }

  if (dataType && dataType === "number") {
    let displayNew = Number(newValue) !== Number(parentData.value) && newValue;
    let increasing =
      newValue && Number(newValue) > Number(parentData.displayString);
    let decreasing =
      newValue && Number(newValue) < Number(parentData.displayString);

    return (
      <List
        disablePadding
        className={classes.container}
        style={{ marginBottom: "1rem", padding: "0 1rem" }}
      >
        <ListItem dense className={classes.displayContainer}>
          <ListItemText
            primary={name}
            secondary={
              <DisplayValues displayNew={displayNew} increasing={increasing} />
            }
            className={classes.display}
            classes={{ secondary: classes.textSecondary }}
          />
          {getStatusIcon(status)}
        </ListItem>
        <NewVFDSlider
          min={0}
          max={100}
          value={Number(parentData.value)}
          setNewValue={setNewValue}
          increasing={increasing}
          decreasing={decreasing}
        />
      </List>
    );
  }

  return (
    <List disablePadding>
      <ListItem key={id} dense>
        <ListItemText primary={name} secondary={parentData.displayString} />
        <ListItemIcon className={classes.icon}>
          {getStatusIcon(status)}
        </ListItemIcon>
      </ListItem>
    </List>
  );
};

const styles = theme => ({
  root: {
    minWidth: "unset",
    marginLeft: "1rem"
  },
  container: {
    margin: "1rem 0",
    justifyContent: "space-evenly"
  },
  icon: {
    justifyContent: "flex-end"
  },
  adjustablePadding: {
    padding: 0
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0
  },
  display: {
    width: "150px",
    display: "flex",
    flexDirection: "column"
  },
  secondaryDisplay: {
    display: "flex",
    alignItems: "center",
    height: 24
  },
  textSecondary: {
    display: "flex",
    alignItems: "center"
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes,
    api: state.firebase.profile.api
  })),
  withStyles(styles)
)(VFD);
