import React from "react";

// MUI
import { Slider } from "@material-ui/core";

// MUI Hooks
import { makeStyles, useTheme } from "@material-ui/core/styles";

// MUI Colors
import { lightBlue, blueGrey, deepOrange } from "@material-ui/core/colors";
import { interpolateHcl } from "d3";

const useStyles = makeStyles(theme => ({
  root: {
    animationFillMode: "forwards",
    color: props => props.color,
    height: 8,
    width: "100%"
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
}));

const DeviceSlider = ({
  min,
  max,
  value,
  setNewValue,
  type,
  handleCommitChange
}) => {
  const theme = useTheme();
  const [sliderValue, setSlidervalue] = React.useState(value);
  let interpolate =
    type === "lightDim"
      ? interpolateHcl(blueGrey[800], lightBlue[200])
      : type === "temperature"
      ? interpolateHcl(blueGrey[800], deepOrange[200])
      : interpolateHcl(theme.palette.primary.light, theme.palette.primary.dark);

  let color = interpolate(sliderValue > 0 ? sliderValue / 100 : 0);

  const classes = useStyles({ color });

  const handleChange = (e, val) => {
    setSlidervalue(val);
    setNewValue(val);
  };

  return (
    <div className={classes.root}>
      <Slider
        min={min}
        max={max}
        onChange={handleChange}
        onChangeCommitted={handleCommitChange}
        value={sliderValue}
        classes={classes}
        valueLabelDisplay="auto"
        aria-label="slider"
      />
    </div>
  );
};

export default DeviceSlider;
