import React, { useState } from "react";
import PropTypes from "prop-types";
//MUI
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
//Icon
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
//HOC
import { makeStyles } from "@material-ui/core/styles";

import { deburr, orderBy } from "lodash";
import Downshift from "downshift";

// Util
import clsx from "clsx";

const renderInput = (
  inputProps,
  controlledInputValue,
  handleInputChange,
  clearSelection
) => {
  const { InputProps, classes, ref, activeStep, ...other } = inputProps;
  return (
    <Paper className={classes.root} square>
      <IconButton className={classes.iconButton} aria-label="Menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        disabled={activeStep === 4}
        className={classes.input}
        placeholder="Search Facility Devices"
        inputProps={{
          ...inputProps,
          disabled: activeStep === 4,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          value: controlledInputValue,
          onChange: handleInputChange
        }}
        {...other}
      />
      <Divider className={classes.divider} />
      <IconButton
        className={classes.iconButton}
        aria-label="Clear"
        onClick={clearSelection}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};

const renderSuggestion = suggestionProps => {
  const {
    activeStep,
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    handleAction
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;
  return (
    <MenuItem
      {...itemProps}
      onClick={() => handleAction(activeStep, suggestion)}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}>
      {suggestion.label}
    </MenuItem>
  );
};
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
  handleAction: PropTypes.func.isRequired
};

const getSuggestions = (value, data, { showEmpty = false } = {}) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : buildSuggestions(data).filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  container: {
    flexGrow: 1,
    position: "relative",
    padding: "1rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0"
    }
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  inputDisabled: {
    background: theme.palette.action.disabledBackground
  }
}));

const buildSuggestions = data => {
  if (!data) {
    return [{ label: "", id: {} }];
  } else {
    return orderBy(
      data.map(suggestion => {
        return { label: suggestion.name, id: suggestion };
      }),
      ["name"],
      ["asc"]
    );
  }
};

const Searchbar = props => {
  const { action, activeStep, data } = props;
  const [controlledInputValue, setControlledInputValue] = useState("");

  const steps = ["building", "floor", "space", "device", "point"];

  const activeSearch = !activeStep
    ? "Search ..."
    : activeStep === 0
    ? `Search ${steps[activeStep]}s...`
    : activeStep === 4
    ? ""
    : `Search ${activeStep}s...`
    ? `Search ${steps[activeStep]}...`
    : "Search...";

  const classes = useStyles();

  const handleAction = (activeStep, suggestion) => {
    action(activeStep, suggestion.id);
    setControlledInputValue("");
  };

  const handleInputChange = event => {
    setControlledInputValue(event.target.value);
  };

  const clearSelection = () => {
    setControlledInputValue("");
  };

  return (
    <div className={classes.root}>
      <Downshift id="downshift-simple">
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            onChange: handleInputChange,
            value: controlledInputValue,
            placeholder: activeSearch,
            disabled: activeStep === 4,
            onKeyDown: event => {
              if (event.key === "Enter") {
                event.nativeEvent.preventDownshiftDefault = true;
                const suggestions = getSuggestions(controlledInputValue, data);
                handleAction(steps[activeStep], suggestions[highlightedIndex]);
              }
            }
          });

          return (
            <div className={classes.container}>
              {renderInput(
                {
                  fullWidth: true,
                  classes,
                  InputProps: { onBlur, onFocus },
                  inputProps,
                  activeStep
                },
                controlledInputValue,
                handleInputChange,
                clearSelection
              )}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper
                    className={clsx(classes.paper, {
                      [classes.inputDisabled]: activeStep === 4
                    })}
                    square>
                    {getSuggestions(controlledInputValue, data).map(
                      (suggestion, index) =>
                        renderSuggestion({
                          activeStep: steps[activeStep],
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion.label,
                            action: suggestion.action
                          }),
                          highlightedIndex,
                          selectedItem,
                          handleAction,
                          isOpen
                        })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default Searchbar;
