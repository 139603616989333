import React from "react";
import PropTypes from "prop-types";

// MUI
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import { Clear, Close, Edit, Security, Visibility } from "@material-ui/icons";

// Util
import { find, orderBy } from "lodash";
import { isEmpty } from "react-redux-firebase";
import { deepOrange } from "@material-ui/core/colors";

const permissions = [
  {
    text: "read",
    icon: <Visibility style={{ paddingLeft: ".5rem" }} />
  },
  {
    text: "write",
    icon: <Edit style={{ paddingLeft: ".5rem" }} />
  },
  {
    text: "read write",
    icon: <Security style={{ paddingLeft: ".5rem" }} />
  }
];

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    color: "white",
    marginLeft: theme.spacing(2),
    flex: 1
  },
  gridItem: {
    padding: "0 1rem"
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  }
}));

const RolesDialogNew = ({
  // Dialog Hooks`
  open,
  setOpen,

  // Data Hooks
  roles,
  setRoles,

  // Data
  selectedDevices,

  // Edit
  editRole,
  setEditRole
}) => {
  const classes = useStyles();

  const [selectAll, setSelectAll] = React.useState({
    readOnly: false,
    readWrite: false
  });

  const handleSelectAll = type => {
    let devices = selectedDevices.map(sD => ({
      id: sD.device,
      name: sD.deviceName,
      points: sD.points.map(point => {
        return {
          ids: {
            clientId: sD.client,
            siteId: sD.site,
            buildingId: sD.building,
            floorId: sD.floor,
            spaceId: sD.space,
            deviceId: sD.device,
            pointId: point.id
          },
          names: {
            clientName: sD.clientName,
            siteName: sD.siteName,
            buildingName: sD.buildingName,
            floorName: sD.floorName,
            spaceName: sD.spaceName,
            deviceName: sD.deviceName,
            pointName: point.name
          },
          permission: point.readOnly ? "read" : type,
          readOnly: point.readOnly
        };
      })
    }));

    let selectedPoints = [];

    if (
      (selectAll.readOnly && type === "read") ||
      (selectAll.readWrite && type === "read write")
    ) {
      setSelectAll({ readOnly: false, readWrite: false });
      return setRole({
        ...role,
        devices,
        selectedPoints: []
      });
    }

    selectedDevices.forEach(device =>
      device.points.forEach(point =>
        selectedPoints.push({
          ids: {
            clientId: device.client,
            siteId: device.site,
            buildingId: device.building,
            floorId: device.floor,
            spaceId: device.space,
            deviceId: device.device,
            pointId: point.id
          },
          names: {
            clientName: device.clientName,
            siteName: device.siteName,
            buildingName: device.buildingName,
            floorName: device.floorName,
            spaceName: device.spaceName,
            deviceName: device.deviceName,
            pointName: point.name
          },
          permission: point.readOnly ? "read" : type,
          readOnly: point.readOnly
        })
      )
    );

    if (type === "read") {
      setSelectAll({ ...selectAll, readOnly: true, readWrite: false });
    } else {
      setSelectAll({ ...selectAll, readOnly: false, readWrite: true });
    }

    setRole({
      ...role,
      devices,
      selectedPoints
    });
  };

  // Module Hooks
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  // Convenience
  const isEditing = !isEmpty(editRole);

  // Role
  const defaultRole = {
    roleName: "",
    devices: [],
    selectedPoints: []
  };
  const [role, setRole] = React.useState(defaultRole);
  const [inUse, setInUse] = React.useState(false);

  // Change Handlers
  const handleChange = name => event => {
    let roleNameUsed = roles.filter(r => r.roleName === event.target.value);
    if (roleNameUsed.length) {
      setInUse(true);
    } else {
      setInUse(false);
    }
    setRole({ ...role, [name]: event.target.value });
  };

  // Effects
  // Handle Edit
  React.useEffect(() => {
    if (isEditing) {
      setRole(editRole);
    }
  }, [isEditing, editRole]);

  // Database Functions
  // Save
  const handleSave = () => {
    setRoles([...roles, { ...role }]);
    // Cleanup
    setRole({ roleName: "", devices: [], selectedPoints: [] });
    setOpen(false);
  };

  // Edit
  const handleEdit = () => {
    const newRoles = roles.filter(role => role.roleName !== editRole.roleName);
    setRoles([...newRoles, { ...role }]);
    // Cleanup
    setEditRole({});
    setRole({ roleName: "", devices: [], selectedPoints: [] });
    setOpen(false);
  };

  const handlePermission = ({
    pointId,
    pointName,
    device,
    permission,
    readOnly
  }) => {
    let deviceId = device.device;
    // Point
    const payload = {
      readOnly,
      ids: {
        clientId: device.client,
        siteId: device.site,
        buildingId: device.building,
        floorId: device.floor,
        spaceId: device.space,
        deviceId: device.device,
        pointId: pointId
      },
      names: {
        clientName: device.clientName,
        siteName: device.siteName,
        buildingName: device.buildingName,
        floorName: device.floorName,
        spaceName: device.spaceName,
        deviceName: device.deviceName,
        pointName: pointName
      },
      permission
    };

    // Check if device exists
    let roleDevice = find(role.devices, ["id", deviceId]);

    // If it exists, check if the point exists
    if (roleDevice) {
      let devicePoint = find(
        roleDevice.points,
        point => point.ids.pointId === pointId
      );

      // If the point exists, remove it, replace the device points, and refresh the devices
      if (devicePoint) {
        let newPoints = roleDevice.points.filter(
          point => point.ids.pointId !== pointId
        );

        let newSelectedPoints = role.selectedPoints.filter(
          point => point.ids.pointId !== pointId
        );

        if (devicePoint.permission === permission) {
          roleDevice.points = newPoints;
        } else {
          roleDevice.points = [...newPoints, { ...payload }];
          newSelectedPoints = [...newSelectedPoints, { ...payload }];
        }

        // Set Existing Points
        setRole({
          ...role,
          devices: roleDevice.points.length
            ? role.devices
            : role.devices.filter(d => d.id !== deviceId),
          selectedPoints: newSelectedPoints
        });
      } else {
        // If the point doesn't exist, add it
        let newDevices = role.devices.filter(device => device.id !== deviceId);
        roleDevice.points = [...roleDevice.points, { ...payload }];

        // Set New Device Point
        setRole({
          ...role,
          devices: [...newDevices, roleDevice],
          selectedPoints: [...role.selectedPoints, { ...payload }]
        });
      }
    } else {
      // If the device doesn't exist, add it to the list
      setRole({
        ...role,
        devices: [
          ...role.devices,
          {
            id: deviceId,
            name: device.deviceName,
            points: [{ ...payload }]
          }
        ],
        selectedPoints: [...role.selectedPoints, { ...payload }]
      });
    }
  };

  const handleDelete = () => {
    const newRoles = roles.filter(role => role.roleName !== editRole.roleName);
    setRoles(newRoles);
    // Cleanup
    setOpenDeleteDialog(false);
    setEditRole({});
    setRole({ roleName: "", devices: [], selectedPoints: [] });
    setOpen(false);
  };

  // Delete Dialog
  const DeleteDialog = ({ open, onClose, roleName }) => {
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle>Delete Role ({roleName})</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is permanent, and will remove this role, and the
            permissions granted to all subscribed users. Use with caution.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Exit
          </Button>
          <Button
            onClick={() => handleDelete()}
            variant="contained"
            style={{ background: deepOrange[500], color: "white" }}
          >
            Confirm Delete
          </Button>
        </DialogActions>{" "}
      </Dialog>
    );
  };

  console.log(selectedDevices);
  console.log(role);

  return (
    <Dialog
      fullScreen
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* Delete Dialog */}

      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        roleName={role.roleName}
      />

      {/* DeleteDialog */}
      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Set Point Access for Role
          </Typography>
          <Button
            color="inherit"
            onClick={isEditing ? () => handleEdit() : () => handleSave()}
            disabled={
              !role.devices.length || !role.roleName || (!isEditing && inUse)
            }
          >
            {isEditing ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </AppBar>

      <List style={{ margin: "1rem" }}>
        <ListItem style={{ justifyContent: "center" }}>
          <TextField
            autoFocus
            id="outlined-name"
            label="Role Name"
            value={role.roleName}
            onChange={handleChange("roleName")}
            helperText={
              inUse
                ? "This role name is already in use. Try a different one."
                : ""
            }
            margin="normal"
            variant="outlined"
            style={{ margin: "1rem" }}
            error={inUse}
          />

          {isEditing && (
            <Button
              onClick={() => setOpenDeleteDialog(true)}
              variant="contained"
              style={{ background: deepOrange[500], color: "white" }}
            >
              Delete
            </Button>
          )}
        </ListItem>

        <Divider />

        <ListItem style={{ justifyContent: "space-evenly" }}>
          <Button
            variant="contained"
            color={selectAll.readOnly ? "primary" : "inherit"}
            onClick={() => handleSelectAll("read")}
          >
            {" "}
            Read Only
          </Button>
          <Button
            variant="contained"
            color={selectAll.readWrite ? "primary" : "inherit"}
            onClick={() => handleSelectAll("read write")}
          >
            {" "}
            Read Write
          </Button>
        </ListItem>

        <Divider />

        {orderBy(
          selectedDevices.filter(sD => sD.type === "device"),
          ["deviceName"],
          ["asc"]
        ).map((device, idx) => {
          return (
            <ListItem
              key={`${device.key}__${idx}`}
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "1rem"
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <ListItemText
                  style={{ paddingBottom: "1rem", width: "100%" }}
                  primary={`${device.title} Points`}
                  secondary={`${device.clientName} > ${device.siteName} > ${device.buildingName} >  ${device.floorName} >  ${device.spaceName} > ${device.title}`}
                />
              </div>
              {device.points.map((point, idx) => {
                const pointId = point.id;
                return (
                  <Grid
                    container
                    key={`${pointId}__${idx}`}
                    style={{
                      padding: ".5rem 0",
                      justifyContent: "space-evenly"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        primary={device.name}
                        style={{ textTransform: "capitalize" }}
                      >
                        {point.name}
                      </Typography>
                    </Grid>
                    {permissions.map(permission => {
                      let existingPoint = find(
                        role.selectedPoints,
                        point => point.ids.pointId === pointId
                      );

                      let disabled =
                        point.readOnly &&
                        (permission.text === "write" ||
                          permission.text === "read write");
                      return (
                        <Grid
                          item
                          xs={4}
                          md={3}
                          key={permission.text}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "1rem"
                          }}
                        >
                          <Button
                            className={classes.button}
                            color="primary"
                            style={{
                              background: disabled
                                ? "rgba(0,0,0,.12)"
                                : existingPoint &&
                                  existingPoint.ids.pointId === point.id &&
                                  existingPoint.permission === permission.text
                                ? "#3f51b5"
                                : "inherit"
                            }}
                            variant={
                              existingPoint &&
                              existingPoint.ids.pointId === point.id &&
                              existingPoint.permission === permission.text
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handlePermission({
                                readOnly: point.readOnly,
                                pointName: point.name,
                                pointId,
                                device,
                                permission: permission.text
                              })
                            }
                            disabled={disabled}
                          >
                            <Typography
                              variant="caption"
                              style={{
                                color: disabled ? "rgba(0,0,0,.26)" : "inherit"
                              }}
                            >
                              {permission.text === "read write"
                                ? "both"
                                : permission.text.toUpperCase()}
                            </Typography>
                            {disabled ? (
                              <Clear style={{ color: "rgba(0,0,0,.26)" }} />
                            ) : (
                              permission.icon
                            )}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
              <Divider />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

RolesDialogNew.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  setRoles: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  editRole: PropTypes.object.isRequired,
  setEditRole: PropTypes.func.isRequired
};

export default RolesDialogNew;
