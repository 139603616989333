import React, { Component } from "react";

import { withFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";

import { history } from "../store";
import { push } from "connected-react-router";

//Router
import { ConnectedRouter } from "connected-react-router";
import Routes from "../routes";

//Styles
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  MuiThemeProvider,
  LinearProgress,
  createMuiTheme
} from "@material-ui/core";

import { defaultTheme } from "../assets/themes";
import Login from "../pages/Login";

class App extends Component {
  render() {
    const { firebase } = this.props;
    let theme = defaultTheme;

    if (
      !isLoaded(firebase.auth) &&
      !isLoaded(firebase.profile) &&
      !isEmpty(firebase.auth)
    ) {
      return <LinearProgress />;
    } else if (isLoaded(firebase.auth) && isEmpty(firebase.auth)) {
      return <Login />;
    }

    const {
      profile: { whitelabel: { primary = "", secondary = "" } = {} }
    } = firebase;

    if (firebase.profile.whitelabel) {
      if (
        (primary && primary.length === 7) ||
        (secondary && secondary.length === 7)
      ) {
        theme = createMuiTheme({
          ...defaultTheme,
          palette: {
            primary: {
              main: primary ? primary : defaultTheme.palette.primary.main
            },
            secondary: {
              main: secondary ? secondary : defaultTheme.palette.secondary.main
            }
          }
        });
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </MuiThemeProvider>
    );
  }
}

export default compose(
  connect(
    state => ({
      firebase: state.firebase
    }),
    dispatch => ({
      reroute: route => dispatch(push(route))
    })
  ),
  withFirebase
)(App);
