import React from "react";

// MUI
import { Slider } from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Colors
import { lightBlue } from "@material-ui/core/colors";
import { interpolateHcl } from "d3";

const useStyles = makeStyles(theme => ({
  root: {
    animationFillMode: "forwards",
    color: props => props.color,
    height: 8,
    width: "100%"
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
}));

const NewVFDSlider = ({ min, max, value, setNewValue }) => {
  const [sliderValue, setSlidervalue] = React.useState(value);

  let interpolate = interpolateHcl(lightBlue[200], lightBlue[800]);
  let color = interpolate(sliderValue > 0 ? sliderValue / 100 : 0);

  const classes = useStyles({ color });

  const handleChange = (e, val) => {
    setSlidervalue(val);
    setNewValue(val);
  };

  return (
    <div className={classes.root}>
      <Slider
        min={min}
        max={max}
        onChange={handleChange}
        value={sliderValue}
        classes={classes}
        valueLabelDisplay="auto"
        aria-label="slider"
      />
    </div>
  );
};

export default NewVFDSlider;
