import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";

import { flatten } from "lodash";
// MUI
import CalendarContainer from "../components/Calendar/CalendarContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//Components
import { isEmpty } from "lodash";
import { LinearProgress } from "@material-ui/core";

const Loader = () => {
  return (
    <Container
      maxWidth="md"
      style={{
        position: "absolute",
        display: "flex",
        top: "50%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress variant="indeterminate" color="primary" />
    </Container>
  );
};

class Calendars extends Component {
  componentDidMount() {
    if (Boolean(this.props.calendars.length)) {
      this.props.updateDataReducer("calendars", []);
    }
    this.setState({ site: this.props.match.params.site });
    this.props.getData(this.props.api, "buildings", null, "buildings");
  }

  render() {
    const {
      buildings,
      calendars,
      fetching,
      match,
      updateDataReducer
    } = this.props;
    const site = match.params.site;

    if (isEmpty(buildings)) {
      return <LinearProgress />;
    }

    const siteBuildings = buildings.filter(
      building => building.siteId === site
    );

    if (fetching) {
      return <Loader />;
    }

    // Filter out Calendars
    if (!Boolean(siteBuildings.length)) {
      return <div> No calendars to show </div>;
    } else if (!Boolean(calendars.length)) {
      let tempcal,
        calendars = [];
      siteBuildings.forEach(building => {
        if (building.devices) {
          building.devices.forEach(device => {
            if (device.points) {
              tempcal = device.points.filter(
                point => point.dataObjectType === "calendar"
              );
              if (tempcal.length)
                calendars.push({
                  building: building.name,
                  device: device.name,
                  calendar: tempcal
                });
            }
          });
        }
      });

      if (!Boolean(calendars.length)) {
        return (
          <Typography variant="h6" align="center">
            No Active Calendars to Display
          </Typography>
        );
      }

      //   Update
      updateDataReducer("calendars", flatten(calendars));

      return <Loader />;
    }

    if (Boolean(calendars.length)) {
      const colors = ["#F67280", "#C06C84", "#6C5B7B", "#355C7D"];

      return (
        <Grid
          container
          style={{
            margin: "2rem 0",
            padding: "2rem 0",
            justifyContent: "space-evenly"
          }}
        >
          {calendars.map((calendar, idx) => {
            return (
              <CalendarContainer
                key={`${calendar.device}_${idx}`}
                data={calendar}
                color={colors[idx % calendars.length]}
              />
            );
          })}
        </Grid>
      );
    } else {
      return (
        <Typography variant="h6" align="center">
          No Active Calendars to Display
        </Typography>
      );
    }
  }
}

export default connect(
  state => ({
    api: state.firebase.profile.api,
    constants: state.constants,
    buildings: state.data.buildings,
    calendars: state.data.calendars,
    fetching: state.data.fetching
  }),
  dispatch => ({
    reroute: route => dispatch(push(route)),
    getData: (api, filter, params, storeAs) =>
      dispatch({
        type: ACTIONS.DATA_SAGA,
        payload: { api, filter, params, storeAs }
      }),
    updateDataReducer: (aspect, payload) => {
      dispatch({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect,
        payload
      });
    }
  })
)(Calendars);
