import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { MobileStepper, Step, StepLabel, IconButton } from "@material-ui/core";
import { Replay } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 46
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepper: {
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 0
    }
  },
  mobileStepper: {
    height: "100%",
    width: "100%",
    flexGrow: 1
  },
  mobileProgress: {
    width: "100%"
  }
}));

function getSteps() {
  return ["Buildings", "Floors", "Spaces", "Devices"];
}

const NewAccessStepper = ({ activeStep, values, handleReset }) => {
  const classes = useStyles();
  const steps = getSteps();

  const getLabel = idx => {
    switch (idx) {
      case 0: {
        if (values.building.name) {
          return values.building.name;
        } else {
          return "Buildings";
        }
      }
      case 1: {
        if (values.floor.name) {
          return values.floor.name;
        } else {
          return "Floors";
        }
      }
      case 2: {
        if (values.space.name) {
          return values.space.name;
        } else {
          return "Spaces";
        }
      }
      case 3: {
        if (values.device.name) {
          return values.device.name;
        } else {
          return "Devices";
        }
      }

      default: {
        return "";
      }
    }
  };

  // Mobile Stepper
  return (
    <div className={classes.root}>
      <MobileStepper
        variant="progress"
        steps={5}
        position="static"
        activeStep={activeStep}
        classes={{ progress: classes.mobileProgress }}
        className={classes.mobileStepper}
        nextButton={
          activeStep > 0 ? (
            <IconButton size="small" onClick={handleReset}>
              <Replay />
            </IconButton>
          ) : (
            <span />
          )
        }>
        {steps.map((_, index) => {
          const stepProps = {};
          const labelProps = {};
          const label = getLabel(index);
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </MobileStepper>
    </div>
  );
};
export default NewAccessStepper;
