import React from "react";

// MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// MUI Colors
import { lightBlue } from "@material-ui/core/colors";

// Util
import { isEmpty } from "lodash";

// Modules
import AddUserNew from "./GroupNew/AddUserNew";

const useStyles = makeStyles(theme => ({
  card: {
    width: 300,
    maxWidth: 345,
    boxShadow: theme.shadows[3]
  },
  cardContent: {
    padding: "0 1rem 1rem 1rem"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  list: {
    height: 200,
    overflow: "hidden",
    overflowY: "auto"
  },
  listItem: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0
  },
  listItemText: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%"
  },
  primaryText: {
    marginRight: "1rem"
  },
  secondaryText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right"
  },
  actions: {
    boxShadow: theme.shadows[3]
  },
  // Brand
  primary: {
    color: props => (props.primary ? props.primary : "inherit")
  },
  secondary: {
    color: props => (props.secondary ? props.secondary : "inherit")
  }
}));

const GroupNew = ({
  profile,
  group,
  groups,
  open,
  setOpen,
  data,
  id,
  selection,
  currentMember,
  // Edit
  setCreateGroupVariant,
  setEditGroupData,
  setOpenCreateGroup
}) => {
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  const {
    name: groupName,
    roles,
    whitelabel: {
      companyName,
      name,
      phone,
      email,
      text,
      site,
      icon,
      iconBackground,
      primary,
      secondary
    } = {}
  } = group;

  const {
    permissionsAccess: { canAddUsers, canEditThisGroup } = {
      canAddUsers: true,
      canEditThisGroup: true
    }
  } = profile;

  const classes = useStyles({ primary, secondary });

  const handleSetOpen = data => {
    setEditGroupData(data);
    setCreateGroupVariant("edit");
    setOpenCreateGroup(true);
  };

  return (
    <Grid
      item
      style={{
        padding: "1rem",
        display: "flex",
        justifyContent: "space-evenly"
      }}
    >
      <Card
        className={classes.card}
        style={{
          border: currentMember ? `1px solid ${lightBlue[500]}` : "none"
        }}
      >
        <AddUserNew
          open={openAddUser}
          setOpen={setOpenAddUser}
          id={id}
          data={data}
          group={group}
          groups={groups}
          users={users}
          setUsers={setUsers}
          currentMember={currentMember}
        />
        <CardActions
          style={{ justifyContent: "space-between" }}
          className={classes.actions}
        >
          {Boolean(canEditThisGroup) && (
            <Button
              size="small"
              color="primary"
              onClick={() => handleSetOpen(group)}
            >
              Edit
            </Button>
          )}

          {currentMember && (
            <Typography variant="caption">Current Group</Typography>
          )}

          {Boolean(canAddUsers) && (
            <Button
              size="small"
              color="primary"
              onClick={() => setOpenAddUser(true)}
            >
              Manage Users
            </Button>
          )}
        </CardActions>

        {/* Whitelabel */}

        <CardContent className={classes.cardContent}>
          <Typography
            variant="h5"
            align="center"
            style={{ margin: ".75rem .5rem" }}
          >
            {groupName}
          </Typography>

          {/* Brand */}
          {icon && (
            <div
              style={{
                padding: "1rem",
                background: iconBackground ? "black" : "white"
              }}
            >
              <img
                alt="brand icon"
                src={icon}
                style={{ marginBottom: "1rem", width: "100%" }}
              />
            </div>
          )}
        </CardContent>

        {/* Company Details */}
        {!isEmpty(group.whitelabel) && (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography align="left" className={classes.heading}>
                Company Details
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List
                component="nav"
                style={{ width: "100%" }}
                aria-labelledby="nested-list-subheader"
              >
                {companyName && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Name"
                      secondary={companyName}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}

                {primary || secondary ? (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary={"Brand Colors"}
                      secondary={
                        <span
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            component="span"
                            className={classes.primary}
                          >
                            {primary}
                          </Typography>
                          <Typography
                            component="span"
                            className={classes.secondary}
                          >
                            {secondary}
                          </Typography>
                        </span>
                      }
                    />
                  </ListItem>
                ) : null}

                {site && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Site"
                      secondary={site}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {!isEmpty(group.whitelabel) && (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography align="left" className={classes.heading}>
                Contact Details
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List
                component="nav"
                style={{ width: "100%" }}
                aria-labelledby="nested-list-subheader"
              >
                {name && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Name"
                      secondary={name}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}

                {email && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Email"
                      secondary={email}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}

                {phone && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Phone"
                      secondary={phone}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}

                {text && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary="Text"
                      secondary={text}
                      classes={{
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                      }}
                    />
                  </ListItem>
                )}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography align="left" className={classes.heading}>
              Roles
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List
              component="nav"
              style={{ width: "100%" }}
              aria-labelledby="nested-list-subheader"
            >
              {roles &&
                roles.map(role => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      key={role.roleName}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                        paddingBottom: "1rem"
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{
                          width: "100%",
                          fontSize: "1.1rem",
                          fontWeight: "bold"
                        }}
                      >
                        {role.roleName}
                      </Typography>

                      {role.devices.map((device, idx) => {
                        return (
                          <ListItemText
                            key={`${device.id}_${idx}`}
                            classes={{
                              primary: classes.primaryText,
                              secondary: classes.secondaryText
                            }}
                            className={classes.listItemText}
                            primary={device.name}
                            secondary={device.points.map((point, idx) => (
                              <Typography
                                variant="caption"
                                key={`${point.ids.pointId}_${idx}`}
                              >
                                {point.names.pointName}
                              </Typography>
                            ))}
                          />
                        );
                      })}
                    </ListItem>
                  );
                })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography align="left" className={classes.heading}>
              Users
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List
              component="nav"
              style={{ width: "100%" }}
              aria-labelledby="nested-list-subheader"
            >
              {group.users &&
                group.users.map(user => {
                  return (
                    <ListItem
                      alignItems="flex-start"
                      className={classes.listItem}
                      key={user.id}
                      style={{ marginBottom: "2rem" }}
                      // style={{ display: "flex", flexDirection: "column" }}
                    >
                      <ListItemText
                        // style={{ alignItems: "center" }}
                        // classes={{ primary: classes.primaryText }}
                        className={classes.listItemText}
                        primary={`${user.last.toUpperCase()}, ${user.first}`}
                        secondary={
                          <span>
                            Email: {user.email} <br />
                            Role: {user.role} <br />
                            Status: {user.status}
                          </span>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    </Grid>
  );
};

export default GroupNew;
