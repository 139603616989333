import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";
// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

//Components
import AccessStepper from "../components/DeviceAccess/AccessStepper";
import Buildings from "../components/DeviceAccess/Buildings";
import Floors from "../components/DeviceAccess/Floors";
import Spaces from "../components/DeviceAccess/Spaces";
import Devices from "../components/DeviceAccess/Devices";
import Points from "../components/DeviceAccess/Points";
import Searchbar from "../components/Searchbar";

class DeviceAccess extends Component {
  state = {
    activeStep: 0,
    site: "",
    building: "",
    floor: "",
    space: "",
    device: "",
    point: ""
  };

  componentDidMount() {
    if (!this.props.api) {
      this.props.reroute("/");
      return;
    }
    this.setState({ site: this.props.match.params.site });
    this.props.getData(this.props.api, "buildings", null, "buildings");
  }

  render() {
    const { buildings, fetching } = this.props;
    const { activeStep } = this.state;

    const floorData = () => {
      if (this.state.building === "") return [];

      let building = buildings.filter(bldg => {
        return (
          this.state.building === bldg.id && bldg.siteId === this.state.site
        );
      });

      if (building.length) {
        return building[0].floors;
      } else {
        return [];
      }
    };

    const spaceData = () => {
      if (this.state.building === "" || this.state.floor === "") return [];

      let floors = floorData();
      let floor = floors.filter(floor => this.state.floor === floor.id);

      if (floor.length) {
        return floor[0].spaces;
      } else {
        return [];
      }
    };

    const deviceData = () => {
      if (
        this.state.building === "" ||
        this.state.floor === "" ||
        this.state.space === ""
      )
        return [];

      let spaces = spaceData();
      let space = spaces.filter(space => this.state.space === space.id);

      if (space.length) {
        return space[0].devices;
      } else {
        return [];
      }
    };

    const pointData = () => {
      if (
        this.state.building === "" ||
        this.state.floor === "" ||
        this.state.space === "" ||
        this.state.device === ""
      )
        return [];

      let devices = deviceData();
      let device = devices.filter(device => this.state.device === device.id);

      if (device.length) {
        return device[0];
      } else {
        return [];
      }
    };

    const suggestions = () => {
      switch (activeStep) {
        case 0: {
          return buildings;
        }
        case 1: {
          return floorData();
        }
        case 2: {
          return spaceData();
        }
        case 3: {
          return deviceData();
        }
        default: {
          return buildings;
        }
      }
    };

    if (fetching) {
      return (
        <Container
          maxWidth="md"
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress variant="indeterminate" color="primary" />
        </Container>
      );
    }

    return (
      <>
        <CssBaseline />
        {activeStep < 4 ? (
          <Searchbar
            activeStep={activeStep}
            action={this.handleNext.bind(this)}
            data={suggestions()}
          />
        ) : null}
        <Container maxWidth="md" style={{ padding: "0 0 2rem 0" }}>
          <AccessStepper
            activeStep={activeStep}
            pointData={pointData()}
            parentState={this.state}
            handleBack={this.handleBack.bind(this)}
            handleNext={this.handleNext.bind(this)}
            handleStep={this.handleStep.bind(this)}
            handleReset={this.handleReset.bind(this)}
          >
            <Buildings
              value="Buildings"
              id={this.state.building}
              site={this.state.site}
              data={buildings}
              reroute={this.props.reroute}
              handleNext={this.handleNext.bind(this)}
              updateDataReducer={this.props.updateDataReducer}
            />
            <Floors
              value="Floors"
              id={this.state.floor}
              data={floorData()}
              activeStep={activeStep}
              handleBack={this.handleBack.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
            <Spaces
              value="Spaces"
              id={this.state.space}
              data={spaceData()}
              activeStep={activeStep}
              handleBack={this.handleBack.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
            <Devices
              value="Devices"
              id={this.state.device}
              data={deviceData()}
              activeStep={activeStep}
              handleBack={this.handleBack.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
            <Points
              value="Points"
              data={pointData()}
              buildings={
                Array.isArray(buildings) &&
                buildings.filter(
                  building => building.id === this.state.building
                )
              }
              activeStep={activeStep}
              handleBack={this.handleBack.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
          </AccessStepper>
        </Container>
      </>
    );
  }

  handleNext = (step, id) => {
    this.setState({ activeStep: this.state.activeStep + 1 });

    switch (step) {
      case "building": {
        this.setState({ building: id });
        break;
      }

      case "floor": {
        this.setState({ floor: id });
        break;
      }

      case "space": {
        this.setState({ space: id });
        break;
      }

      case "device": {
        this.setState({ device: id });
        break;
      }

      default: {
        return;
      }
    }
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleStep = step => {
    this.setState({ activeStep: step });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      building: "",
      floor: "",
      space: "",
      device: ""
    });
  };
}

export default connect(
  state => ({
    api: state.firebase.profile.api,
    constants: state.constants,
    buildings: state.data.buildings,
    fetching: state.data.fetching
  }),
  dispatch => ({
    reroute: route => dispatch(push(route)),
    getData: (api, filter, params, storeAs) =>
      dispatch({
        type: ACTIONS.DATA_SAGA,
        payload: { api, filter, params, storeAs }
      }),
    updateDataReducer: (aspect, payload) => {
      dispatch({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect,
        payload
      });
    }
  })
)(DeviceAccess);
