import React from "react";
import { connect } from "react-redux";
import { functions } from "../../firebase/firebase";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { isEmpty, keys, pickBy } from "lodash";

import RemoveIcon from "@material-ui/icons/Remove";

import uuid from "uuid";

import MomentUtils from "@date-io/moment";
import { green } from "@material-ui/core/colors";
import { CircularProgress } from "@material-ui/core";
import { UPDATE_DATA_ASPECT } from "../../actions";

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "white"
  },
  listItem: {
    paddingLeft: "2rem",
    paddingBottom: "1rem"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  iconBorder: {
    color: "white",
    background: props => (props.iconBorder ? green[500] : "none")
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ScheduleDialog = props => {
  const {
    api,
    role,
    id,
    user,
    open,
    setOpen,
    scheduleData,
    setNeedsUpdate
  } = props;

  const mappedDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];

  const handleInitialLoad = () => {
    const existingData = { ...scheduleData };
    delete existingData.current;
    let existingSaved = [];
    Object.keys(existingData).forEach(day => {
      if (existingData[day].length > 0) {
        existingData[day].forEach(daySched => {
          existingSaved.push({
            id: uuid.v4(),
            days: [day.toLowerCase()],
            startTime: daySched.timeStart,
            endTime: daySched.timeEnd,
            eventValue: daySched.value === "Occupied" ? true : false
          });
        });
      }
    });
    return existingSaved;
  };

  const [savedTimes, setSavedTimes] = React.useState(handleInitialLoad());
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [updating, setUpdating] = React.useState(false);

  const classes = useStyles({ iconBorder: startTime && endTime });

  const [days, setDays] = React.useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false
  });

  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    setOpen("");
  };

  const handleDayChange = day => {
    setDays({ ...days, [day]: days[day] ? false : true });
  };

  const handleSave = () => {
    setUpdating(true);
    setNeedsUpdate(true);
    let values = [];

    savedTimes.forEach(s => {
      s.days.forEach(day => {
        values.push({
          action: "set",
          dayOfWeek: day,
          startTime: s.startTime,
          endTime: s.endTime,
          eventValue: s.eventValue
        });
      });
    });

    const postSchedule = functions.httpsCallable("postSchedule");
    const fetchRoute = functions.httpsCallable("fetchEndpoint");

    postSchedule({ api, id, role, user, value: values }).then(res => {
      handleClose("");
      setUpdating(false);

      setTimeout(() => {
        fetchRoute({ route: "buildings", api })
          .then(result => {
            if (!isEmpty(result.data)) {
              return JSON.parse(result.data);
            } else return {};
          })
          .then(res => {
            if (isEmpty(res)) {
              return null;
            } else {
              props.update(res);
            }
          });
      }, 30000);
    });
  };

  const handleValue = val => {
    setValue(val);
  };

  const handleAddTimes = () => {
    setSavedTimes([
      ...savedTimes,
      {
        id: uuid.v4(),
        days: keys(pickBy(days)),
        startTime: startTime.format("HH:mm"),
        endTime: endTime.format("HH:mm"),
        eventValue: value === "True" ? true : false
      }
    ]);

    setDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false
    });
    setStartTime(null);
    setEndTime(null);
    setValue("");
  };

  const handleRemoveSavedItem = id => {
    let saveTimeCopy = [...savedTimes];
    setSavedTimes(saveTimeCopy.filter(s => s.id !== id));
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Set Schedule
            </Typography>
            <Button
              color="inherit"
              onClick={handleSave}
              disabled={!Boolean(savedTimes.length) || updating}
            >
              {updating ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                "save"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem style={{ paddingLeft: "2rem" }}>
            <ListItemText
              primary="Select Day(s)"
              secondary="Select one or multiple days to schedule for this point"
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Grid
              item
              style={{
                padding: "1rem 0",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              {mappedDays.map(day => {
                return (
                  <Button
                    key={day}
                    color="primary"
                    onClick={() => handleDayChange(day)}
                    variant={days[day] ? "contained" : "outlined"}
                    style={{ borderRadius: 0 }}
                  >
                    {day}
                  </Button>
                );
              })}
            </Grid>
          </ListItem>
          <Divider light={true} />

          {/* Values */}
          <ListItem style={{ paddingLeft: "2rem" }}>
            <ListItemText
              primary="Select Value"
              secondary="Select the value to trigger during the scheduled event"
            />
          </ListItem>
          <ListItem style={{ padding: "2rem" }}>
            <Button
              color="primary"
              onClick={() => handleValue("True")}
              variant={value === "True" ? "contained" : "outlined"}
              style={{
                borderRadius: "4px 0 0 4px"
              }}
            >
              True
            </Button>
            <Button
              color="primary"
              onClick={() => handleValue("False")}
              variant={value === "False" ? "contained" : "outlined"}
              style={{
                borderRadius: "0 4px 4px 0"
              }}
            >
              False
            </Button>
          </ListItem>
          <Divider light={true} />

          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Select Time(s)"
              secondary="Select desired start and end times for the schedule"
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            style={{
              justifyContent: matches ? "space-between" : "normal"
            }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid
                item
                xs={4}
                style={{
                  paddingBottom: "1rem"
                }}
              >
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={setStartTime}
                />
              </Grid>
              <Grid item xs={4} style={{ paddingBottom: "1rem" }}>
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={setEndTime}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </ListItem>
          <Grid
            item
            xs={8}
            style={{
              paddingLeft: "2rem",
              paddingBottom: "2rem"
            }}
          >
            {startTime &&
            endTime &&
            keys(pickBy(days)).length > 0 &&
            value !== "" ? (
              <Button
                onClick={handleAddTimes}
                className={classes.iconBorder}
                disabled={
                  !startTime ||
                  !endTime ||
                  keys(pickBy(days)).length === 0 ||
                  value === ""
                }
                variant="contained"
              >
                Add Schedule
              </Button>
            ) : null}
          </Grid>
          <Divider />
          <Divider />
          <Divider />

          {/* Scheduled Times */}
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Scheduled Times"
              secondary="Desired scheduled times"
            />
          </ListItem>
          <Divider light={true} />

          <Grid item xs={12} style={{ padding: "1rem 0" }}>
            {savedTimes.map(s => {
              return (
                <ListItem key={s.id} style={{ paddingLeft: "2rem" }}>
                  <ListItemText
                    primary={s.days
                      .toString()
                      .split(",")
                      .join(", ")}
                    secondary={`${s.startTime} to ${s.endTime}`}
                    style={{ fontVariant: "all-small-caps" }}
                  />
                  <ListItemText
                    primary="Value:"
                    secondary={s.eventValue ? "True" : "False  "}
                    style={{ fontVariant: "all-small-caps" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      size="small"
                      aria-label="delete"
                      color="secondary"
                      style={{ border: "1px solid", marginRight: "1rem" }}
                      onClick={() => handleRemoveSavedItem(s.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </Grid>
        </List>
      </Dialog>
    </div>
  );
};

export default connect(
  state => ({}),
  dispatch => ({
    update: data =>
      dispatch({
        type: UPDATE_DATA_ASPECT,
        aspect: "buildings",
        payload: data
      })
  })
)(ScheduleDialog);
