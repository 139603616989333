import React, { useCallback } from "react";

// MUI
import { List, Typography } from "@material-ui/core";

// MUI Styles
import { makeStyles } from "@material-ui/core/styles";

// Components
import Device from "./Device";
import { Websocket } from "./Websocket";

// Styles
const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

// Component
export default function DeviceDialog({ selectedDevice, profile }) {
  const classes = useStyles();

  // Websocket
  const [socketData, setSocketData] = React.useState({
    id: "",
    name: "",
    points: []
  });

  // Parse incoming socket data
  const handleSocketData = useCallback(
    lastMessage => {
      setSocketData(JSON.parse(lastMessage.data));
    },
    [setSocketData]
  );

  // Cleanup
  React.useEffect(() => {
    return () => {
      setSocketData({
        id: "",
        name: "",
        points: []
      });
    };
  }, []);

  // Render
  return (
    <div>
      <Websocket
        endpoint={selectedDevice.id}
        socketData={socketData}
        handleSocketData={handleSocketData}
      />

      <Typography variant="h4" className={classes.title}>
        {selectedDevice.name}
      </Typography>

      <List>
        {selectedDevice.points.map(point => {
          return (
            <Device
              key={point.id}
              point={point}
              socketData={socketData}
              name={point.name}
              profile={profile}
            />
          );
        })}
      </List>
    </div>
  );
}
