import React from "react";
import { connect } from "react-redux";

//Routes
import { Route, Switch } from "react-router";
import { SnackbarProvider } from "notistack";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Landing from "../pages/Landing";
import Alarms from "../pages/Alarms";
import Calendars from "../pages/Calendars";
import Climate from "../pages/Climate";
import History from "../pages/History";
import Lights from "../pages/Lights";
import Login from "../pages/Login";
import NewDashboard from "../pages/NewDashboard";
import PointPage from "../pages/PointPage";
import Profile from "../pages/Profile";
import DeviceAccess from "../pages/DeviceAccess";

import AdminNew from "../pages/AdminNew";

//SUITES
//Auth
export const LANDING = "/";
export const ALARMS = "/alarms";
export const CALENDARS = "/calendars";
export const CLIMATE = "/climate";
export const DASHBOARD = "/dashboard";
export const DEVICE_ACCESS = "/deviceaccess";
export const HISTORY = "/history";
export const LIGHTS = "/lights";
export const LOGIN = "/login";
export const POINT = "/point";
export const PROFILE = "/profile";

export const ADMIN = "/admin";

//Routes Object
const Routes = ({ profile, data, role }) => (
  <div style={{ background: "white" }}>
    <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
      <Navigation />
      <Switch>
        <Route
          exact
          path={DASHBOARD}
          render={() => <NewDashboard profile={profile} data={data} />}
        />
        <Route exact path={`${ALARMS}/:site`} component={Alarms} />
        <Route exact path={`${CALENDARS}/:site`} component={Calendars} />
        <Route exact path={`${CLIMATE}/:site`} component={Climate} />
        <Route exact path={`${HISTORY}/:site`} component={History} />
        <Route exact path={`${DEVICE_ACCESS}/:site`} component={DeviceAccess} />
        <Route exact path={LANDING} component={Landing} />
        <Route exact path={`${LIGHTS}/:site`} component={Lights} />
        <Route
          exact
          path={`${POINT}/:buildingId/:floorId/:spaceId/:deviceId/:id`}
          component={PointPage}
        />
        <Route exact path={PROFILE} component={Profile} />

        <Route exact path={ADMIN} component={AdminNew} />
        <Route exact path={LOGIN} component={Login} />

        {/* Fallback */}
        <Route render={() => <NewDashboard profile={profile} data={data} />} />
      </Switch>
      <Footer />
    </SnackbarProvider>
  </div>
);

export default connect(state => ({
  profile: state.firebase.profile,
  data: state.data,
  role: state.firebase.profile.role
}))(Routes);
