import React from "react";

//MUI
import { Grid, Switch, withStyles } from "@material-ui/core";

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        background: `linear-gradient(90deg, #93a5cf 0%, #e4efe9 100%)`,
        borderColor: theme.palette.common.white
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

const DeviceToggle = ({ value, setNewValue, handleCommitChange }) => {
  const [checked, setChecked] = React.useState(value);

  const handleChange = e => {
    setChecked(e.target.checked);
    handleCommitChange(e, e.target.checked);
  };

  return (
    <div>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Off</Grid>
        <Grid item>
          <StyledSwitch
            checked={checked}
            onChange={handleChange}
            value={checked}
          />
        </Grid>
        <Grid item>On</Grid>
      </Grid>
    </div>
  );
};

export default DeviceToggle;
