import React from "react";

import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Collapse,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import {
  AccountCircle,
  ExpandLess,
  ExpandMore,
  Menu,
  SupervisedUserCircle,
  Security
} from "@material-ui/icons";

// MUI Util
import clsx from "clsx";
import { orderBy } from "lodash";
import { ADMIN } from "../../routes";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    transition: "background 500ms ease",
    marginTop: 64,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 56
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: { ...theme.mixins.toolbar, transition: "background 750ms linear" },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    width: "calc(100% - 240px)",
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  selected: {
    color: theme.palette.secondary.main
  }
}));

const DashboardMenu = ({
  children,
  clientData,
  fetching,
  reroute,
  selectClients,
  setValues,
  values,
  profile
}) => {
  const classes = useStyles();

  const { client, site } = values;
  const { setClient } = setValues;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openClientList, setOpenClientList] = React.useState(true);

  const handleClientList = () => {
    setOpenClientList(!openClientList);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSelect = id => {
    setClient(id);
    // setMobileOpen(false);
  };

  const selectedClient = clientData.filter(c => {
    return c.client.id === client;
  });

  React.useEffect(() => {
    if (!fetching) {
      selectClients(clientData);
    }

    // selectedClient required, clientData//selectClients === exhaustive deps
    // eslint-disable-next-line
  }, [clientData]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider style={{ margin: 0 }} />
      <List disablePadding>
        {clientData.length ? (
          <ListItem button onClick={handleClientList}>
            <ListItemIcon>
              <SupervisedUserCircle />
            </ListItemIcon>
            <ListItemText primary="Clients" />
            {openClientList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : (
          <LinearProgress />
        )}
        <Divider style={{ margin: 0 }} />

        {/* Client List */}
        <Collapse in={openClientList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {orderBy(clientData, cli => cli.client.name).map(dClient => {
              return (
                <ListItem
                  button
                  onClick={() => handleSelect(dClient.client.id)}
                  className={clsx(classes.nested, {
                    [classes.selected]: client === dClient.client.id
                  })}
                  key={dClient.client.id}
                  value={dClient.client.id}
                >
                  <ListItemIcon>
                    <AccountCircle
                      className={
                        client === dClient.client.id ? classes.selected : null
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={dClient.client.name} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>

        {/* Administration Card */}
        <Collapse
          in={Boolean(client) && Boolean(site)}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Divider style={{ margin: 0 }} />

            <ListItem>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      style={{ display: "inline" }}
                    >
                      {selectedClient[0] && selectedClient[0].client.name}{" "}
                      Options
                    </Typography>
                    {
                      " — Here's where you'll find additional options for security and user management"
                    }
                  </React.Fragment>
                }
              />
            </ListItem>
            {(profile && !profile.permissionsAccess) ||
            (profile &&
              profile.permissionsAccess &&
              profile.permissionsAccess.hasPermissionsAccess) ? (
              <ListItem button onClick={() => reroute(ADMIN)}>
                <ListItemIcon>
                  <Security />
                </ListItemIcon>
                <ListItemText primary="User Management" />
              </ListItem>
            ) : null}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="primary">
        <Toolbar style={{ minHeight: 54 }} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            {fetching || !clientData.length ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              <Menu />
            )}
          </IconButton>

          <Typography variant="body1" noWrap style={{ color: "white" }}>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default DashboardMenu;
