import React from "react";
import BrandIcon from "../../static/Icons/tapaIcon.png";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI
import {
  AppBar,
  Dialog,
  IconButton,
  ListItem,
  Slide,
  Toolbar,
  Grid,
  LinearProgress,
  Breadcrumbs,
  Link,
  Typography
} from "@material-ui/core";

// MUI Icons
import NewDeviceAccess from "../../pages/NewDeviceAccess";

// Util
import { orderBy } from "lodash";
import { Clear } from "@material-ui/icons";

// Styles
const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper)
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  breadcrumbContainer: {
    padding: "0 .5rem",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    height: 35
  }
}));

// Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Component
const DeviceDialog = props => {
  const classes = useStyles();
  const { open, close, api, client, site, fetchBuildings, profile } = props;

  const defaultValues = {
    activeStep: 0,
    building: {},
    floor: {},
    space: {},
    device: {},
    point: {}
  };
  const [values, setValues] = React.useState(defaultValues);
  const { activeStep } = values;

  // Functions
  const [buildingData, setBuildingData] = React.useState([]);

  // State
  const [fetching, setFetching] = React.useState(false);

  const [breadcrumbs, setBreadcrumbs] = React.useState([{}]);

  const handleReset = () => {
    setValues(defaultValues);
    setBreadcrumbs([{}]);
  };

  const handleClose = () => {
    handleReset();
    close();
  };

  //   Stepper Buttons
  //   Handle stepper
  const handleNext = (step, data) => {
    setValues({ ...values, activeStep: activeStep + 1, [step]: data });
  };

  //   Handle stepper
  const handleBack = (step, data) => {
    setValues({ ...values, activeStep: activeStep - 1, [step]: data });
  };

  const getBreadcrumbs = activeStep => {
    if (!buildingData.length) return [{}];

    switch (activeStep) {
      case 1: {
        //   Breadcrumbs.length check and adjust
        setBreadcrumbs([{ name: values.building.name, step: 1 }]);
        break;
      }
      case 2: {
        setBreadcrumbs([...breadcrumbs, { name: values.floor.name, step: 2 }]);
        break;
      }
      case 3: {
        setBreadcrumbs([...breadcrumbs, { name: values.space.name, step: 3 }]);
        break;
      }
      case 4: {
        setBreadcrumbs([...breadcrumbs, { name: values.device.name, step: 4 }]);
        break;
      }
      default: {
        setBreadcrumbs([{}]);
      }
    }
  };

  //   Load new data automatically as site updates
  React.useEffect(() => {
    if (api && client && site && !fetching) {
      setFetching(true);
      const data = fetchBuildings({ route: "buildings", api });
      data.then(res => {
        setFetching(false);
        const data = JSON.parse(res.data);
        const filteredData = data.filter(building => building.siteId === site);
        setBuildingData(filteredData);
      });
    }

    // eslint-disable-next-line
  }, [site]);

  React.useEffect(() => {
    getBreadcrumbs(activeStep);
    // eslint-disable-next-line
  }, [values]);

  const loadOptions = options => {
    switch (options) {
      case "buildings": {
        return orderBy(buildingData, ["name"]).map((data, idx) => {
          if (
            !data.floors.length ||
            !data.spaces.length ||
            !data.devices.length
          ) {
            return <span key={`__bldg_${idx}`} />;
          }

          return (
            <ListItem
              key={data.id}
              button
              onClick={() => handleNext("building", data)}
            >
              <Typography variant="h4">
                <b>{data.name}</b>
              </Typography>
            </ListItem>
          );
        });
      }

      case "floors": {
        return orderBy(values.building.floors, ["name"]).map((data, idx) => {
          if (!data.spaces.length || !data.devices.length) {
            return <span key={`__floors_${idx}`} />;
          }

          return (
            <ListItem
              key={data.id}
              button
              onClick={() => handleNext("floor", data)}
            >
              <Typography variant="h4">
                <b>{data.name}</b>
              </Typography>{" "}
            </ListItem>
          );
        });
      }

      case "spaces": {
        return orderBy(values.floor.spaces, ["name"]).map((data, idx) => {
          if (!data.devices.length) {
            return <span key={`__space_${idx}`} />;
          }

          return (
            <ListItem
              key={data.id}
              button
              onClick={() => handleNext("space", data)}
            >
              <Typography variant="h4">
                <b>{data.name}</b>
              </Typography>{" "}
            </ListItem>
          );
        });
      }

      case "devices": {
        return orderBy(values.space.devices, ["name"]).map(data => {
          return (
            <ListItem
              key={data.id}
              button
              onClick={() => handleNext("device", data)}
            >
              <Typography variant="h4">
                <b>{data.name}</b>
              </Typography>{" "}
            </ListItem>
          );
        });
      }

      default: {
        return <span />;
      }
    }
  };

  const handleBreadcrumb = step => {
    if (step !== activeStep) {
      setBreadcrumbs(breadcrumbs.slice(0, step - 1));
      setValues({ ...values, activeStep: step });
    }
  };

  let logo = BrandIcon;
  if (profile.whitelabel) {
    logo = profile.whitelabel.icon ? profile.whitelabel.icon : BrandIcon;
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <div className={classes.imgWrapper} onClick={handleClose}>
              <img
                src={logo}
                alt={"logo"}
                height={"100%"}
                style={{ margin: ".5rem", cursor: "pointer" }}
              />
            </div>

            {fetching ? (
              <Grid item style={{ width: "100%" }}>
                <LinearProgress style={{ width: "100%" }} />
              </Grid>
            ) : (
              <span style={{ width: "100%" }} />
            )}

            <IconButton
              color="inherit"
              onClick={handleClose}
              disabled={fetching}
              style={{ color: "black" }}
            >
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container style={{ padding: "1rem", marginTop: 56 }}>
          {/* Breadcrumbs */}
          <Grid item xs={12} className={classes.breadcrumbContainer}>
            {!fetching && (
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                {activeStep > 0 ? (
                  breadcrumbs.map((crumb, idx) => {
                    return (
                      <Link
                        disabled={activeStep === crumb.step}
                        color="inherit"
                        key={`${crumb}_${idx}`}
                        onClick={() => handleBreadcrumb(crumb.step)}
                      >
                        {crumb.name ? crumb.name : ""}
                      </Link>
                    );
                  })
                ) : (
                  <Link color="inherit" key={`init_crumb`}>
                    Select a building
                  </Link>
                )}
              </Breadcrumbs>
            )}
          </Grid>

          <Grid item xs={12}>
            <NewDeviceAccess
              buildingData={buildingData}
              setBuildingData={setBuildingData}
              fetching={fetching}
              setFetching={setFetching}
              values={values}
              setValues={setValues}
              handleReset={handleReset}
              parentProps={{ ...props }}
              handleBack={handleBack}
              handleNext={handleNext}
              loadOptions={loadOptions}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default DeviceDialog;
