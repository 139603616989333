import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";

//HOCS
import { withStyles } from "@material-ui/core/styles";
import { green, deepOrange, lightBlue, orange } from "@material-ui/core/colors";

//Components
import NewTempSlider from "../NewSlider/NewTempSlider";

const getStatusIcon = currentState => {
  switch (currentState) {
    case "updating": {
      return (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      );
    }

    case "normal": {
      return <CheckCircleIcon style={{ color: green[400] }} />;
    }

    case "error": {
      return <CancelIcon style={{ color: deepOrange[400] }} />;
    }

    default: {
      return <CheckCircleIcon />;
    }
  }
};

const Temperature = props => {
  const {
    classes,
    api,
    data,
    didAlarm,
    id,
    deviceId,
    name,
    readOnly,
    state,
    getData,
    min,
    max
  } = props;

  const { displayString, value } = data;

  const [ready, setReady] = useState(false);
  const [wsClient, setWsClient] = useState();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [parentData, setParentData] = useState({
    displayString,
    state,
    value
  });

  const [newValue, setNewValue] = React.useState("");

  // TENNENT
  useEffect(() => {
    setParentData({
      displayString,
      state,
      value
    });
    setNeedsUpdate(needsUpdate);
  }, [displayString, state, value, needsUpdate]);

  // Set up socket
  if (!wsClient && !ready) {
    const client = new WebSocket(
      "wss://ptxrcj27wa.execute-api.us-east-1.amazonaws.com/v1"
    );

    client.onopen = evt => {
      setReady(true);
      setWsClient(client);
    };

    client.onclose = evt => {
      setWsClient();
    };

    client.onerror = evt => {
      setWsClient();
      client.close();
    };
  }

  if (ready && needsUpdate && wsClient) {
    // Socket
    wsClient.send(
      JSON.stringify({
        action: "subscribe",
        devices: [deviceId]
      })
    );

    wsClient.onmessage = evt => {
      if (evt.data) {
        const data = JSON.parse(evt.data);
        if (data.data) {
          if (data.id === id) {
            getData(api, "buildings", null, "buildings");
          }
        }
      }
    };
  }

  if (data.status === "unknown") {
    return <div />;
  }

  const DisplayValues = ({ displayNew, isWarming }) => (
    <span className={classes.secondaryDisplay}>
      {/* Show Original Value */}
      {parentData.displayString}

      {/* Show New Value if EXISTS or IS DIFFERENT */}
      {displayNew ? (
        <span className={classes.secondaryDisplay}>
          {isWarming ? (
            <KeyboardArrowRight style={{ color: orange[500] }} />
          ) : (
            <KeyboardArrowLeft style={{ color: lightBlue[500] }} />
          )}
          {
            <b
              style={{
                color: isWarming ? orange[500] : lightBlue[500]
              }}
            >
              {newValue}
            </b>
          }
        </span>
      ) : null}
    </span>
  );

  if (readOnly) {
    return (
      <List disablePadding className={classes.container}>
        <ListItem dense className={classes.adjustablePadding}>
          <ListItemText primary={name} secondary={parentData.displayString} />
          <ListItemIcon className={classes.icon}>
            {didAlarm ? (
              <CancelIcon style={{ color: deepOrange[400] }} />
            ) : (
              getStatusIcon(parentData.state.currentState)
            )}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  } else if (typeof readOnly === "boolean" && readOnly === false) {
    // Convenience
    let displayNew =
      Number(newValue) !== Number(parentData.displayString) && newValue;
    let isWarming =
      newValue && Number(newValue) > Number(parentData.displayString);
    let isCooling =
      newValue && Number(newValue) < Number(parentData.displayString);

    // Component
    return (
      <List
        disablePadding
        className={classes.container}
        style={{ marginBottom: "1rem" }}
      >
        <ListItem dense className={classes.displayContainer}>
          <ListItemText
            primary={name}
            secondary={
              <DisplayValues displayNew={displayNew} isWarming={isWarming} />
            }
            className={classes.display}
            classes={{ secondary: classes.textSecondary }}
          />

          {/* <Slide
            api={api}
            deviceId={deviceId}
            domain={getDomain()}
            id={id}
            state={parentData.state}
            value={parentData.value}
            needsUpdate={needsUpdate}
            setNeedsUpdate={setNeedsUpdate}
            disableNumbers={false}
            wsClient={wsClient}
          /> */}
          {didAlarm ? (
            <CancelIcon style={{ color: deepOrange[400] }} />
          ) : (
            getStatusIcon(parentData.state.currentState)
          )}
        </ListItem>
        <NewTempSlider
          min={min}
          max={max}
          value={parentData.value}
          setNewValue={setNewValue}
          increasing={isWarming}
          decreasing={isCooling}
        />
      </List>
    );
  } else {
    return <div />;
  }
};

const styles = theme => ({
  root: {
    minWidth: "unset",
    marginLeft: "1rem"
  },
  container: {
    justifyContent: "space-evenly"
  },
  icon: {
    justifyContent: "flex-end"
  },
  adjustablePadding: {
    padding: 0
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0
  },
  display: {
    width: "150px",
    display: "flex",
    flexDirection: "column"
  },
  secondaryDisplay: {
    display: "flex",
    alignItems: "center",
    height: 24
  },
  textSecondary: {
    display: "flex",
    alignItems: "center"
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes,
    api: state.firebase.profile.api
  })),
  withStyles(styles)
)(Temperature);
