import React from "react";

// DB
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { store } from "../firebase/firebase";

// UTIL
import * as ACTIONS from "../actions";
import clsx from "clsx";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import scrollToComponent from "react-scroll-to-component";

// MUI
import { Grid, LinearProgress, Button, IconButton } from "@material-ui/core";

// MUI Theme
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "../assets/themes";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Parallax from "../components/Parallax/Parallax";

// Styles
import styles from "../assets/jss/pages/Admin";

// Modules
import CreateGroupNew from "../components/AdminNew/CreateGroupNew";
import GroupNew from "../components/AdminNew/GroupNew";
import { ArrowDownward } from "@material-ui/icons";

const useStyles = makeStyles({
  ...styles,
  parallaxContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
});

const AdminNew = props => {
  const classes = useStyles();

  const { auth, buildings, data, getData, profile, reroute, selection } = props;

  const {
    permissionsAccess: { canCreateGroup } = {
      canCreateGroup: true
    }
  } = profile;

  // Dialog Control
  const [openCreateGroup, setOpenCreateGroup] = React.useState(false);

  // Status
  const [fetching, setFetching] = React.useState(false);
  const [fetchingGroups, setFetchingGroups] = React.useState(false);

  // User Parent Group
  const [parentGroup, setParentGroup] = React.useState({});

  // Edit Groups
  const [createGroupVariant, setCreateGroupVariant] = React.useState("");
  const [editGroupData, setEditGroupData] = React.useState({});

  // Scroll to groups
  let groupRef = React.useRef();
  const handleScroll = (elRef, offset) => {
    scrollToComponent(elRef, {
      offset,
      align: "top",
      duration: 750,
      ease: "inOutQuint"
    });
  };

  // Reroute
  if (!selection.clients.length) {
    reroute("/");
    return <span />;
  }

  const getParentGroup = ({ parent, parentGroup }) => {
    setFetchingGroups(true);
    return store
      .doc(`users/${parent}/groups/${parentGroup}`)
      .get()
      .then(doc => {
        setFetchingGroups(false);
        setParentGroup({ ...doc.data(), groupId: doc.id });
        return doc.data();
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  };

  const loadGroups = () => {
    const loadData = new Promise((res, rej) => {
      res(getData(profile.api, "buildings", null, "buildings"));
      rej(false);
    });

    loadData.then(res => {
      if (profile.parentGroup) {
        getParentGroup({
          parent: profile.parent,
          parentGroup: profile.parentGroup
        });
      }
    });
  };

  if (!buildings.length && !fetching && !fetchingGroups) {
    setFetching(true);
    loadGroups();
  }

  if (buildings.length && fetching) {
    setFetching(false);
  }

  let groupData = {};
  if (isLoaded(data.users) && !isEmpty(data.users) && !isEmpty(profile)) {
    groupData = data.users[auth.uid].groups ? data.users[auth.uid].groups : {};
  }

  //   Loading Screen
  if (!buildings || fetching || !auth.isLoaded || !profile.isLoaded) {
    return (
      <Grid align="center" style={{ padding: 0 }}>
        <Parallax filter image={require("../assets/img/sign.jpg")}>
          <div className={classes.container}>
            <h1 className={classes.title} style={{ paddingTop: "2rem" }}>
              Tapa Authentication Portal
            </h1>
            <h4
              style={{
                color: "white",
                fontWeight: 200,
                padding: "2rem 1rem"
              }}
            >
              The Tapa Authentication Portal provides you with a way to create,
              organize, and manage permissions for your team
            </h4>
            <br />
            <LinearProgress
              color="secondary"
              style={{
                margin: "2rem 1rem"
              }}
            />
          </div>
        </Parallax>
      </Grid>
    );
  }

  const createPermissionGroup = () => {
    setCreateGroupVariant("");
    setEditGroupData({});
    setOpenCreateGroup(true);
  };

  //   Main Component
  return (
    <Grid align="center" style={{ padding: 0 }}>
      {/* Dialogs */}
      <CreateGroupNew
        auth={auth}
        profile={profile}
        api={profile.api}
        data={data}
        open={openCreateGroup}
        setOpen={setOpenCreateGroup}
        treeData={selection.clients}
        // Edit
        createGroupVariant={createGroupVariant}
        setCreateGroupVariant={setCreateGroupVariant}
        editGroupData={editGroupData}
        setEditGroupData={setEditGroupData}
      />

      {/* Splash Page */}
      <Parallax filter image={require("../assets/img/sign.jpg")}>
        <div className={clsx(classes.container, classes.parallaxContainer)}>
          <h1 className={classes.title} style={{ paddingTop: "2rem" }}>
            Tapa Authentication Portal
          </h1>
          <h4
            style={{
              color: "white",
              fontWeight: 200,
              padding: "2rem 1rem"
            }}
          >
            The Tapa Authentication Portal provides you with a way to create,
            organize, and manage permissions for your team
          </h4>
          <br />

          {canCreateGroup && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => createPermissionGroup()}
              style={{
                padding: "1rem",
                fontSize: "1rem",
                letterSpacing: ".1rem"
                // color: "black"
              }}
            >
              Create Permissions Group
            </Button>
          )}

          {/* Go to Groups */}
          {buildings.length ? (
            <IconButton
              style={{ margin: "2rem 0 1rem 0", border: "1px solid white" }}
              onClick={() => handleScroll(groupRef, -45)}
            >
              <ArrowDownward style={{ color: "white" }} />
            </IconButton>
          ) : null}
        </div>
      </Parallax>
      <div className={classes.container}>
        <Grid
          container
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly"
          }}
        >
          <div className={classes.container}>
            <Grid
              container
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly"
              }}
              ref={section => {
                groupRef = section;
              }}
            >
              {/* Member Group */}
              {!isEmpty(parentGroup) ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <MuiThemeProvider
                    theme={createMuiTheme({
                      ...defaultTheme,
                      palette: {
                        primary: {
                          main:
                            parentGroup.whitelabel.primary &&
                            parentGroup.whitelabel.primary.length === 7
                              ? parentGroup.whitelabel.primary
                              : defaultTheme.palette.primary.main
                        },
                        secondary: {
                          main:
                            parentGroup.whitelabel.secondary &&
                            parentGroup.whitelabel.secondary.length === 7
                              ? parentGroup.whitelabel.secondary
                              : defaultTheme.palette.secondary.main
                        }
                      }
                    })}
                  >
                    <GroupNew
                      id={parentGroup.id}
                      group={parentGroup}
                      groups={groupData}
                      profile={profile}
                      data={data}
                      selection={selection}
                      currentMember={true}
                      // Edit Group
                      setCreateGroupVariant={setCreateGroupVariant}
                      setEditGroupData={setEditGroupData}
                      setOpenCreateGroup={setOpenCreateGroup}
                    />
                  </MuiThemeProvider>
                </Grid>
              ) : null}

              {groupData &&
                Object.keys(groupData).map(key => {
                  let group = groupData[key];

                  if (!group) return null;

                  let theme = createMuiTheme({
                    ...defaultTheme,
                    palette: {
                      primary: {
                        main:
                          group.whitelabel.primary &&
                          group.whitelabel.primary.length === 7
                            ? group.whitelabel.primary
                            : defaultTheme.palette.primary.main
                      },
                      secondary: {
                        main:
                          group.whitelabel.secondary &&
                          group.whitelabel.secondary.length === 7
                            ? group.whitelabel.secondary
                            : defaultTheme.palette.secondary.main
                      }
                    }
                  });

                  if (group) {
                    return (
                      <Grid item xs={12} sm={6} lg={4} key={key}>
                        <MuiThemeProvider theme={theme}>
                          <GroupNew
                            id={key}
                            group={{ ...group, groupId: key }}
                            groups={groupData}
                            profile={profile}
                            key={group.id}
                            data={data}
                            selection={selection}
                            // Edit Group
                            setCreateGroupVariant={setCreateGroupVariant}
                            setEditGroupData={setEditGroupData}
                            setOpenCreateGroup={setOpenCreateGroup}
                          />
                        </MuiThemeProvider>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
            </Grid>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default compose(
  connect(
    state => ({
      auth: state.firebase.auth,
      buildings: state.data.buildings,
      data: state.firestore.data,
      profile: state.firebase.profile,
      selection: state.selection
    }),
    dispatch => ({
      getData: (api, filter, params, storeAs) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs }
        }),
      reroute: route => dispatch(push(route))
    })
  ),
  firestoreConnect(props => {
    if (!props.auth.uid) return [];
    return [`users/${props.auth.uid}/groups`];
  })
)(AdminNew);
