import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";

//Components
import ClimateStepper from "../components/Climate/ClimateStepper";

import { isEmpty } from "lodash";
import { LinearProgress } from "@material-ui/core";

class Climate extends Component {
  componentDidMount() {
    this.props.getData(this.props.api, "buildings", null, "buildings");
  }

  render() {
    const { buildings, fetching, match } = this.props;
    const site = match.params.site;

    if (isEmpty(buildings)) {
      return <LinearProgress />;
    }

    const siteBuildings = buildings.filter(
      building => building.siteId === site
    );

    if (fetching) {
      return (
        <Container
          maxWidth="md"
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress variant="indeterminate" color="primary" />
        </Container>
      );
    }

    return (
      <>
        <CssBaseline />
        <Typography
          variant="h5"
          style={{
            padding: "2rem 0",
            fontVariant: "all-petite-caps",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%"
          }}
        >
          Climate
        </Typography>
        <Container maxWidth="md" style={{ padding: "0 0 2rem 0" }}>
          <ClimateStepper data={siteBuildings} />
        </Container>
      </>
    );
  }
}

export default connect(
  state => ({
    api: state.firebase.profile.api,
    buildings: state.data.buildings,
    fetching: state.data.fetching
  }),
  dispatch => ({
    reroute: route => dispatch(push(route)),
    getData: (api, filter, params, storeAs) =>
      dispatch({
        type: ACTIONS.DATA_SAGA,
        payload: { api, filter, params, storeAs }
      })
  })
)(Climate);
