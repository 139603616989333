import React from "react";
import PropTypes from "prop-types";

// Firebase
import { db } from "../firebase/firebase";
import { withFirebase, isLoaded } from "react-redux-firebase";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";

// MUI
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  ListItem,
  ListItemText
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Colors
import { lightGreen, green, deepOrange } from "@material-ui/core/colors";

// MUI Icons
import { Edit, Check } from "@material-ui/icons";

// Components
import Parallax from "../components/Parallax/Parallax";

// Styles
import clsx from "clsx";
import styles from "../assets/jss/pages/Admin";

// Images
import PlaceholderIcon from "../static/Icons/tapaIcon.png";

// UTIL
import Uploader from "../components/Uploader/Uploader";

const useStyles = makeStyles(theme => ({
  ...styles,
  container: {
    ...styles.container,
    // marginTop: "3rem",
    zIndex: 1,
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  root: {
    padding: 0
  },
  title: {
    ...styles.title,
    marginTop: 0,
    color: "rgba(255, 255, 255, .87)",
    width: "100%"
  },
  // Dialog
  appBar: {
    position: "relative"
  },
  appBarTitle: {
    fontSize: "1.125rem",
    marginLeft: theme.spacing(2),
    flex: 1
  },
  // Main
  mainRaised: {
    ...styles.mainRaised,
    paddingBottom: "4rem",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      margin: -1
    }
  },
  avatarContainer: {
    position: "absolute",
    left: "calc(50% - 75px)",
    top: -75
  },
  avatar: {
    width: 150,
    height: 150,
    boxShadow: theme.shadows[10]
  },
  textField: {
    padding: "1rem 2rem"
  },
  headerText: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  infoContainer: {
    marginBottom: "2rem"
  }
}));

const Profile = ({ auth, firebase, profile, ...props }) => {
  const classes = useStyles();
  const [editingProfile, setEditingProfile] = React.useState(false);

  const [values, setValues] = React.useState({
    avatar: { url: "" },
    contactEmail: "",
    email: "",
    firstName: "",
    lastName: ""
  });
  const { avatar, email, contactEmail, firstName, lastName } = values;

  const [whitelabel, setWhitelabel] = React.useState({
    companyName: "",
    name: "",
    email: "",
    phone: "",
    text: "",
    site: "",
    icon: "",
    primary: "",
    secondary: ""
  });

  React.useEffect(() => {
    if (profile.profile) {
      setValues({
        avatar: profile.profile.avatar ? profile.profile.avatar : { url: "" },
        contactEmail: profile.profile.contactEmail
          ? profile.profile.contactEmail
          : profile.profile.email,
        email: profile.profile.email,
        firstName: profile.profile.firstName,
        lastName: profile.profile.lastName
      });

      if (profile.whitelabel) {
        setWhitelabel({
          companyName: profile.whitelabel.companyName,
          name: profile.whitelabel.name,
          email: profile.whitelabel.email,
          phone: profile.whitelabel.phone,
          text: profile.whitelabel.text,
          site: profile.whitelabel.site,
          icon: profile.whitelabel.icon,
          primary: profile.whitelabel.primary,
          secondary: profile.whitelabel.secondary
        });
      }
    }
  }, [profile]);

  const updateProfile = () => {
    db.ref(`users/${auth.uid}`)
      .update({
        profile: {
          ...values
        }
      })
      .then(res => {
        setEditingProfile(false);
      });
  };

  const removeAvatar = () => {
    db.ref(`users/${auth.uid}/profile`).update({ avatar: "" });
  };

  return (
    <Paper className={classes.root}>
      <Parallax
        filter
        image={require("../assets/img/profile.jpg")}
        height={"60vh"}
      >
        <div className={classes.container} style={{ padding: "1rem" }}>
          <Grid container>
            {!firstName ? (
              <LinearProgress
                size={20}
                style={{ color: "white", width: "100%" }}
              />
            ) : (
              <Typography variant="h2" align="center" className={classes.title}>
                {`${firstName}'s`} {"profile"}
              </Typography>
            )}
          </Grid>
        </div>
      </Parallax>

      {/* Main Container */}
      <Paper
        className={clsx(
          classes.main,
          classes.mainRaised,
          classes.featuredMobile
        )}
      >
        <Grid container style={{ position: "relative" }}>
          <IconButton
            size="medium"
            onClick={
              !editingProfile
                ? () => setEditingProfile(true)
                : () => updateProfile()
            }
            style={{ position: "absolute", margin: "1rem", top: 0, right: 0 }}
          >
            {editingProfile ? (
              <Check style={{ color: green[500], fontSize: "2rem" }} />
            ) : (
              <Edit style={{ fontSize: "1.5rem" }} />
            )}
          </IconButton>
          <Grid item xs={12}>
            {/* Avatar */}
            <div className={classes.avatarContainer}>
              {isLoaded(profile) && avatar && avatar.url && editingProfile ? (
                <Button
                  onClick={() => removeAvatar()}
                  style={{
                    width: "100%",
                    zIndex: 1,
                    position: "absolute",
                    top: 0,
                    color: "white",
                    background: deepOrange[500],
                    margin: "170px 0"
                  }}
                >
                  Remove Avatar
                </Button>
              ) : null}
              <Avatar
                className={classes.avatar}
                style={{ background: "white" }}
                src={avatar && avatar.url ? avatar.url : null}
                imgProps={{
                  onError: e => (e.target.src = PlaceholderIcon)
                }}
              >
                {!avatar.url && isLoaded(profile) ? (
                  <Uploader
                    text="Avatar"
                    border={true}
                    variant="avatar"
                    storageRef={`avatar/${auth.uid}`}
                    dbPath={`users/${auth.uid}/profile/avatar`}
                  />
                ) : (
                  <CircularProgress
                    thickness={1}
                    style={{
                      width: 150,
                      height: 150,
                      color: lightGreen[500]
                    }}
                  />
                )}
              </Avatar>
            </div>
          </Grid>

          {/* Name / Edit */}
          <Grid
            item
            xs={12}
            style={{
              margin: "100px 0 50px 0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            <Typography variant="h6" align="center">
              Avatar
            </Typography>
          </Grid>
        </Grid>

        {/* Profile Information */}
        <Grid container className={classes.infoContainer}>
          <Typography variant="h4" align="center" style={{ width: "100%" }}>
            Contact Information
          </Typography>
          <Divider />
          <Grid item xs={12} sm={6} className={classes.textField}>
            <TextField
              fullWidth
              value={firstName}
              id="outlined-first"
              label="First Name"
              variant="outlined"
              onChange={e =>
                setValues({ ...values, firstName: e.target.value })
              }
              disabled={!editingProfile}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.textField}>
            <TextField
              fullWidth
              value={lastName}
              id="outlined-last"
              label="Last Name"
              variant="outlined"
              onChange={e => setValues({ ...values, lastName: e.target.value })}
              disabled={!editingProfile}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.textField}>
            <TextField
              fullWidth
              value={contactEmail}
              id="outlined-emailContact"
              label="Contact Email"
              variant="outlined"
              onChange={e =>
                setValues({ ...values, contactEmail: e.target.value })
              }
              disabled={!editingProfile}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.textField}>
            <TextField
              fullWidth
              value={email}
              id="outlined-email"
              label="Sign In Email"
              variant="outlined"
              onChange={e => setValues({ ...values, email: e.target.value })}
              disabled={true || !editingProfile}
              helperText={editingProfile ? "Auth Email Cannot Be Changed" : ""}
            />
          </Grid>
        </Grid>

        {/* Company Information */}
        <Grid container className={classes.infoContainer}>
          <Typography variant="h4" align="center" style={{ width: "100%" }}>
            Company Group
          </Typography>
          <Divider />
          <Grid item xs={12} sm={6}>
            {whitelabel.name && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary="Name"
                  secondary={whitelabel.name}
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText
                  }}
                />
              </ListItem>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {whitelabel.name && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary="Contact"
                  secondary={
                    <span>
                      {`Phone:`} {whitelabel.phone}
                      <br />
                      {`Text: `} {whitelabel.text}
                      <br />
                      {`Email:`} {whitelabel.email}
                    </span>
                  }
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText
                  }}
                />
              </ListItem>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {whitelabel.companyName && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary="Company"
                  secondary={whitelabel.companyName}
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText
                  }}
                />
              </ListItem>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {whitelabel.primary || whitelabel.secondary ? (
              <ListItem className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary={"Brand Colors"}
                  secondary={
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      {whitelabel.primary && (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 4,
                              marginRight: "2rem",
                              background: whitelabel.primary
                            }}
                          />
                          <Typography
                            component="span"
                            className={classes.primary}
                          >
                            {whitelabel.primary}
                          </Typography>
                        </span>
                      )}
                      {whitelabel.secondary && (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 4,
                              marginRight: "2rem",
                              background: whitelabel.secondary
                            }}
                          />
                          <Typography
                            component="span"
                            className={classes.secondary}
                          >
                            {whitelabel.secondary}
                          </Typography>
                        </span>
                      )}
                    </span>
                  }
                />
              </ListItem>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            {whitelabel.site && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary="Site"
                  secondary={whitelabel.site}
                  classes={{
                    primary: classes.primaryText,
                    secondary: classes.secondaryText
                  }}
                />
              </ListItem>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default compose(
  withFirebase,
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }))
)(Profile);
