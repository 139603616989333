import React from "react";
import PropTypes from "prop-types";

// Database
import { store } from "../../firebase/firebase";

// Modules
import {
  AppBar,
  Button,
  Collapse,
  Dialog,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  LinearProgress,
  InputAdornment,
  Checkbox
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import {
  Add,
  CheckCircle,
  Clear,
  Close,
  Edit,
  Remove
} from "@material-ui/icons";

// MUI Colors
import { green, deepOrange } from "@material-ui/core/colors";

// MUI Lab
import Skeleton from "@material-ui/lab/Skeleton";

// Modules
import RolesDialogNew from "./CreateGroupNew/RolesDialogNew";
import SearchableTree from "../Tree/SearchableTree";

// Util
import clsx from "clsx";
import { orderBy } from "lodash";
import * as uuid from "uuid";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "fixed"
  },
  submitButton: {
    background: green[500],
    "&:hover": {
      background: green[700]
    }
  },
  title: {
    color: "white",
    marginLeft: theme.spacing(2),
    flex: 1
  },
  gridItem: {
    padding: "0 1rem"
  },
  brandColorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4
  },
  brandColor: {
    display: "flex",
    height: 40,
    width: 40,
    borderRadius: 4
    //
  }
}));

const CreateGroupNew = props => {
  const classes = useStyles();
  const {
    api,
    auth,
    profile,
    open,
    setOpen,
    treeData,
    createGroupVariant = "",
    editGroupData
  } = props;

  const {
    permissionsAccess: { canAddDevice, canEditRole, canEditWhitelabel } = {
      canAddDevice: true,
      canEditRole: true,
      canEditWhitelabel: true
    }
  } = profile;

  const owner =
    !editGroupData || !editGroupData.creator
      ? false
      : editGroupData.creator === auth.uid
      ? true
      : false;

  // Updating
  const [updating, setUpdating] = React.useState(false);

  // Dialog
  const [openRolesDialog, setOpenRolesDialog] = React.useState(false);

  // Menus
  const [openDevices, setOpenDevices] = React.useState(false);
  const [openRoles, setOpenRoles] = React.useState(false);
  const [openWhitelabel, setOpenWhitelabel] = React.useState(false);

  // Delete Warn
  const [warnDelete, setWarnDelete] = React.useState(false);

  //   Whitelabel
  const defaultWhitelabel = {
    companyName: "Tapa Building Integration",
    site: "www.tapacenter.com",
    // Brand
    icon: "https://demo.tapacenter.com/logo.png",
    iconBackground: false,
    primary: "#145380",
    secondary: "#14a79d",
    // Contact
    name: "Journey Williams",
    phone: "(703) 717-1484",
    text: "(703) 717-1484",
    email: "journey.williams@tapa.cc"
  };
  const [whitelabel, setWhitelabel] = React.useState(defaultWhitelabel);

  //   TextFields
  const defaultValues = {
    name: "Demo Group 1",
    whitelabel: defaultWhitelabel,
    roles: [],
    devices: []
  };
  const [values, setValues] = React.useState(defaultValues);
  const { devices } = values;

  //   Roles
  const [roles, setRoles] = React.useState([]);
  const [editRole, setEditRole] = React.useState({});

  //   Device Selection from Tree
  const [selectedDevices, setSelectedDevices] = React.useState([]);

  // TextField Change Handlers
  // Global
  const handleChangeValues = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  // Whitelabel
  const handleChangeWhitelabel = name => event => {
    setWhitelabel({ ...whitelabel, [name]: event.target.value });
  };

  const [hexError, setHexError] = React.useState({
    primary: "",
    secondary: ""
  });

  const handleChangeHex = name => event => {
    if (event.target.value.length > 7) {
      return setHexError({
        ...hexError,
        [name]: "Too many characters, use format: #000000."
      });
    }

    if (event.target.value[0] !== "#" && event.target.value !== "") {
      setWhitelabel({ ...whitelabel, [name]: whitelabel[name] });
      return setHexError({
        ...hexError,
        [name]: "Hex codes must start with the '#' character."
      });
    }

    setWhitelabel({ ...whitelabel, [name]: event.target.value });
    setHexError({ ...hexError, [name]: "" });
  };

  const handleBlurHex = name => event => {
    if (whitelabel[name].length !== 7) {
      setHexError({
        ...hexError,
        [name]: "Hex code requires 6 characters, eg: #000000."
      });
    } else {
      setHexError({ ...hexError, [name]: "" });
    }
  };

  // Reset Module
  const reset = () => {
    setUpdating(false);
    setValues(defaultValues);
    setRoles([]);
    setEditRole({});
    setSelectedDevices([]);
  };

  // Handle Save
  const handleCreateGroup = () => {
    setUpdating(true);
    const groupId = uuid.v4();

    store
      .collection(`users/${auth.uid}/groups`)
      .add({
        name: values.name,
        id: groupId,
        roles,
        whitelabel,
        selectedDevices,
        creator: auth.uid
      })
      .then(res => {
        store
          .doc(`users/${auth.uid}/groups/${res.id}`)
          .update({
            path: res.path
          })
          .then(res => {
            reset();
            setOpen(false);
            return;
          })
          .catch(e => {
            setWarnDelete("");
            reset();
            setOpen(false);
            console.log(e);
            return e;
          });
      });
  };

  // Handle Update
  const handleUpdateGroup = () => {
    setUpdating(true);
    //
    store
      .doc(`users/${auth.uid}/groups/${editGroupData.groupId}`)
      .update({ name: values.name, roles, whitelabel, selectedDevices })
      .then(res => {
        setWarnDelete("");
        reset();
        setOpen(false);
        return;
      })
      .catch(e => {
        setWarnDelete("");
        reset();
        setOpen(false);
        console.log(e);
        return e;
      });
  };

  // Handle Delete
  const handleDeleteGroup = () => {
    setUpdating(true);
    //
    store
      .doc(`users/${auth.uid}/groups/${editGroupData.groupId}`)
      .delete()
      .then(res => {
        setWarnDelete("");
        reset();
        setOpen(false);
        return;
      })
      .catch(e => {
        setWarnDelete("");
        reset();
        setOpen(false);
        return e;
      });
  };

  //   Loads in existing data when variant === "edit"
  React.useEffect(() => {
    if (createGroupVariant === "edit") {
      let { whitelabel, name, devices, roles, selectedDevices } = editGroupData;
      setValues({
        whitelabel,
        name,
        devices
      });
      setWhitelabel(whitelabel);
      setSelectedDevices(selectedDevices);
      setRoles(roles);
    } else {
      setValues(defaultValues);
      setWhitelabel(defaultWhitelabel);
      setRoles([]);
      setSelectedDevices([]);
    }
    // eslint-disable-next-line
  }, [editGroupData, createGroupVariant]);
  // }, [editGroupData, createGroupVariant]);

  // Edit
  const handleEditRole = role => {
    setEditRole(role);
    setOpenRolesDialog(true);
  };

  // Convenience
  let submitOk = Boolean(
    values.name &&
      roles.length &&
      selectedDevices.length &&
      !hexError.primary &&
      !hexError.secondary
  );
  let checkedKeys = selectedDevices.map(sD => sD.key);

  // Delete Dialog
  const DeleteDialog = ({ open, onClose, name, action }) => {
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: "1rem" }}>
          Delete Group ({name})
        </DialogTitle>
        <DialogContent style={{ padding: "1rem" }}>
          <DialogContentText id="alert-dialog-description">
            This action is permanent, and will remove this group, and the
            permissions granted to all subscribed users. Use with caution.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "1rem" }}
        >
          <Button onClick={onClose} color="primary" disabled={updating}>
            Exit
          </Button>
          <Button
            onClick={action}
            variant="contained"
            style={{ background: deepOrange[500], color: "white" }}
            disabled={updating}
          >
            Delete
          </Button>
        </DialogActions>{" "}
      </Dialog>
    );
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      {/* Dialogs */}
      <RolesDialogNew
        open={openRolesDialog}
        setOpen={setOpenRolesDialog}
        selectedDevices={selectedDevices}
        setRoles={setRoles}
        roles={roles}
        editRole={editRole}
        setEditRole={setEditRole}
      />

      <DeleteDialog
        open={warnDelete === editGroupData.id}
        onClose={() => setWarnDelete("")}
        name={editGroupData.name}
        action={handleDeleteGroup}
      />

      {/* End Dialogs */}

      <Dialog fullScreen open={open} onClose={() => handleClose()}>
        <AppBar>
          <Toolbar style={{ padding: "1rem" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ lineHeight: 1 }}
            >
              Permissions Group
            </Typography>

            {/* On Updating */}
            {updating && (
              <LinearProgress style={{ width: "100%", margin: "0 1rem" }} />
            )}

            {updating ? (
              <CircularProgress size={20} />
            ) : (
              <Button
                color="inherit"
                onClick={
                  createGroupVariant === "edit"
                    ? handleUpdateGroup
                    : handleCreateGroup
                }
                disabled={!submitOk || updating}
                variant={!submitOk ? "outlined" : "contained"}
                className={clsx({
                  [classes.submitButton]: submitOk
                })}
              >
                {createGroupVariant === "edit" ? "update" : "save"}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <List style={{ marginTop: "5rem" }}>
          <ListItem>
            <ListItemText primary="Fill in the group name to assign users to" />
          </ListItem>
          <Grid container>
            <Grid
              item
              xs={createGroupVariant === "edit" ? 6 : 12}
              className={classes.gridItem}
            >
              <TextField
                label="Group Name"
                className={classes.textField}
                value={values.name}
                onChange={handleChangeValues("name")}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            {createGroupVariant === "edit" && !updating ? (
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {owner && (
                  <Button
                    onClick={() => setWarnDelete(editGroupData.id)}
                    variant="outlined"
                    disabled={updating}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            ) : null}
          </Grid>
          <Divider style={{ margin: "1rem 0" }} />
        </List>

        <List style={{ paddingBottom: "5rem" }}>
          {/* Whitelabel */}
          <ListItem
            button
            onClick={() => setOpenWhitelabel(!openWhitelabel)}
            disabled={!canEditWhitelabel}
          >
            {whitelabel.companyName ? (
              <CheckCircle style={{ color: green[500] }} />
            ) : openWhitelabel ? (
              <Remove />
            ) : (
              <Add />
            )}
            <ListItemText
              primary={`Add Whitelabel information to your group`}
              style={{ paddingLeft: "1rem" }}
            />
          </ListItem>

          <Collapse in={openWhitelabel}>
            <Grid container>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Company Name"
                  className={classes.textField}
                  value={whitelabel.companyName}
                  onChange={handleChangeWhitelabel("companyName")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Company Site"
                  className={classes.textField}
                  value={whitelabel.site}
                  onChange={handleChangeWhitelabel("site")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <ListItem>
              <ListItemText secondary="Add contact information" />
            </ListItem>
            <Grid container>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Contact Name"
                  className={classes.textField}
                  value={whitelabel.name}
                  onChange={handleChangeWhitelabel("name")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Email"
                  className={classes.textField}
                  value={whitelabel.email}
                  onChange={handleChangeWhitelabel("email")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Phone Number"
                  className={classes.textField}
                  value={whitelabel.phone}
                  onChange={handleChangeWhitelabel("phone")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  label="Text"
                  className={classes.textField}
                  value={whitelabel.text}
                  onChange={handleChangeWhitelabel("text")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <ListItem>
              <ListItemText secondary="Add brand information" />
            </ListItem>
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                {whitelabel.icon ? (
                  <div
                    style={{
                      display: "inline-block",
                      padding: "1rem",
                      background: whitelabel.iconBackground ? "black" : "white"
                    }}
                  >
                    <img
                      style={{ width: "100%", maxWidth: 300, display: "flex" }}
                      alt={"icon"}
                      src={whitelabel.icon}
                    />
                  </div>
                ) : (
                  <Skeleton variant="rect" width={150} height={150} />
                )}
                <TextField
                  label="Brand Icon"
                  className={classes.textField}
                  value={whitelabel.icon}
                  onChange={handleChangeWhitelabel("icon")}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "100%" }}
                  helperText="Check to use black background"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <Checkbox
                            checked={whitelabel.iconBackground}
                            onChange={e =>
                              setWhitelabel({
                                ...whitelabel,
                                iconBackground: e.target.checked
                              })
                            }
                            value={whitelabel.iconBackground}
                            inputProps={{
                              "aria-label": "use black background"
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.gridItem}
                style={{ display: "flex" }}
              >
                <TextField
                  label="Primary"
                  className={classes.textField}
                  value={whitelabel.primary}
                  onChange={handleChangeHex("primary")}
                  onBlur={handleBlurHex("primary")}
                  margin="normal"
                  variant="outlined"
                  placeholder="#145380"
                  helperText={hexError.primary}
                  error={Boolean(hexError.primary)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          disabled
                        >
                          <div className={classes.brandColorContainer}>
                            <div
                              className={classes.brandColor}
                              style={{
                                background: whitelabel.primary
                                  ? whitelabel.primary
                                  : "#ccc"
                              }}
                            >
                              {" "}
                            </div>
                          </div>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.gridItem}
                style={{ display: "flex" }}
              >
                <TextField
                  label="Secondary"
                  className={classes.textField}
                  value={whitelabel.secondary}
                  onChange={handleChangeHex("secondary")}
                  onBlur={handleBlurHex("secondary")}
                  margin="normal"
                  variant="outlined"
                  placeholder="#14a79d"
                  helperText={hexError.secondary}
                  error={Boolean(hexError.secondary)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          disabled
                        >
                          <div className={classes.brandColorContainer}>
                            <div
                              className={classes.brandColor}
                              style={{
                                background: whitelabel.secondary
                                  ? whitelabel.secondary
                                  : "#ccc"
                              }}
                            >
                              {" "}
                            </div>
                          </div>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
          <Divider style={{ margin: "1rem 0" }} />
          {/* Devices */}
          <ListItem
            button
            onClick={() => setOpenDevices(!openDevices)}
            disabled={updating || !canAddDevice}
          >
            {selectedDevices.length ? (
              <CheckCircle style={{ color: green[500] }} />
            ) : openDevices ? (
              <Remove />
            ) : (
              <Add />
            )}
            <ListItemText
              primary={`Add devices to your permissions group`}
              style={{ paddingLeft: "1rem" }}
            />
          </ListItem>
          <Collapse in={openDevices}>
            <Grid container style={{ padding: "1rem" }}>
              <Grid item xs={12} sm={6}>
                <SearchableTree
                  api={api}
                  data={treeData}
                  devices={devices}
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  checkedKeys={checkedKeys}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <List>
                  {selectedDevices.map(point => {
                    return (
                      <ListItem key={point.key}>
                        <ListItemText
                          primary={point.title}
                          secondary={`${point.clientName} > ${point.siteName} > ${point.buildingName} >  ${point.floorName} >  ${point.spaceName} > ${point.deviceName}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
            {/* Roles */}
          </Collapse>
          <Divider style={{ margin: "1rem 0" }} />
          {/* Roles Title */}
          <ListItem
            button
            onClick={() => setOpenRoles(!openRoles)}
            disabled={!roles.length || updating || !canEditRole}
          >
            {roles.length ? (
              <CheckCircle style={{ color: green[500] }} />
            ) : !selectedDevices.length ? (
              <Clear />
            ) : openRoles ? (
              <Remove />
            ) : (
              <Add />
            )}
            <ListItemText
              primary={
                !roles.length && !selectedDevices.length
                  ? "Select a device above to assign roles"
                  : !roles.length
                  ? "Add roles to assign point access to your users"
                  : "View Roles"
              }
              style={{ paddingLeft: "1rem" }}
            />
            <ListItemSecondaryAction onClick={() => setOpenRolesDialog(true)}>
              <Fab
                variant="extended"
                size="small"
                color="secondary"
                style={{ color: "white", padding: "0 1rem" }}
                aria-label="delete"
                disabled={!selectedDevices.length || updating}
              >
                <Add />
              </Fab>
            </ListItemSecondaryAction>
          </ListItem>

          {/* Add Roles */}
          <Collapse in={openRoles}>
            {/* Active Roles */}

            {roles.map((role, idx) => {
              return (
                <Grid item xs={12} key={`${role.roleName}_${idx}`}>
                  <ListItem
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignItems: "start"
                    }}
                  >
                    <ListItemText
                      primary={role.roleName}
                      secondary={
                        <span style={{ paddingRight: "2rem" }}>
                          {orderBy(
                            role.selectedPoints,
                            point => point.names.deviceName
                          ).map((point, idx) => {
                            return (
                              <span
                                key={`${point.ids.pointId}_${idx}`}
                                style={{
                                  paddingRight: "1rem",
                                  textTransform: "capitalize"
                                }}
                              >
                                {point.names.deviceName} >{" "}
                                {point.names.pointName}:{" "}
                                <b>{point.permission}</b>
                                <br />
                              </span>
                            );
                          })}
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => handleEditRole(role)}
                        disabled={updating}
                      >
                        {" "}
                        <Edit />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Grid>
              );
            })}
          </Collapse>
        </List>
      </Dialog>
    </div>
  );
};

CreateGroupNew.propTypes = {
  api: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  editGroup: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  treeData: PropTypes.array.isRequired,
  // Optional for editing
  groupData: PropTypes.object,
  createGroupVariant: PropTypes.string
};

export default CreateGroupNew;
