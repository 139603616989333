import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
/* eslint-disable */
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
//Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

//HOCS
import { withStyles } from "@material-ui/core/styles";
import { green, deepOrange } from "@material-ui/core/colors";

// Util
import clsx from "clsx";

const getStatusIcon = state => {
  switch (state.currentState) {
    case "updating": {
      return (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      );
    }

    case "normal": {
      return <CheckCircleIcon style={{ color: green[400] }} />;
    }

    case "error": {
      return <CancelIcon style={{ color: deepOrange[400] }} />;
    }

    default: {
      return <CheckCircleIcon />;
    }
  }
};

const AirFlow = props => {
  const {
    classes,
    id,
    parent,
    name,
    value,
    unit,
    displayString,
    status,
    state,
    data,
    readOnly
  } = props;

  console.log(props);

  return (
    <List
      disablePadding
      className={clsx(classes.container, {
        [classes.margin]: readOnly
      })}>
      <ListItem dense className={classes.displayContainer}>
        <ListItemText primary={name} secondary={data.value ? "On" : "Off"} />
        <ListItemIcon className={classes.icon}>
          {getStatusIcon(state)}
        </ListItemIcon>
      </ListItem>
    </List>
  );
};

const styles = theme => ({
  container: {
    justifyContent: "space-evenly"
  },
  margin: {
    margin: "2rem 0"
  },
  icon: {
    justifyContent: "flex-end"
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes
  })),
  withStyles(styles)
)(AirFlow);
