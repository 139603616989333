import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

//MUI
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

//Component
import LoginCard from "../components/Landing/LoginCard";

//Mask
import {
  SIGN_IN_EMAIL,
  AUTH_SAGA,
  UPDATE_SESSION_ASPECT,
  SIGN_UP_EMAIL
} from "../actions";
import { DASHBOARD } from "../routes";
import { push } from "connected-react-router";

class Landing extends Component {
  state = {
    auth: null,
    isFlipped: true,
    showPassword: false,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    loginEmail: "",
    loginPassword: ""
  };

  render() {
    const { profile, reroute } = this.props;

    if (isLoaded(profile) && !isEmpty(profile)) {
      reroute(DASHBOARD);
    }

    return (
      <Grid
        container
        style={{ justifyContent: "flex-end", position: "relative" }}
      >
        <LoginCard />
      </Grid>
    );
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleLoginClickShowPassword = () => {
    this.setState(state => ({ showLoginPassword: !state.showLoginPassword }));
  };

  handleFlip = () => {
    const { clearErrors } = this.props;
    this.setState(state => ({ isFlipped: !state.isFlipped }));
    clearErrors();
  };

  handleSignIn = () => {
    const { signInEmail } = this.props;
    const { loginEmail, loginPassword } = this.state;
    signInEmail({ email: loginEmail, password: loginPassword });
  };

  handleSignUp = () => {
    const { signUpEmail } = this.props;
    const { email, firstName, lastName, phone, password } = this.state;
    signUpEmail({ email, password, firstName, lastName, phone });
  };
}

const mapStateToProps = state => ({
  profile: state.firebase.profile,
  error: state.session.error
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  reroute: route => dispatch(push(route)),
  clearErrors: () => {
    dispatch({
      type: UPDATE_SESSION_ASPECT,
      aspect: "error",
      payload: ""
    });
  },
  signInEmail: payload =>
    dispatch({
      type: AUTH_SAGA,
      filter: SIGN_IN_EMAIL,
      payload
    }),
  signUpEmail: payload =>
    dispatch({
      type: AUTH_SAGA,
      filter: SIGN_UP_EMAIL,
      payload
    })
});

const styles = theme => ({
  root: {
    backgroundColor: "transparent"
  },
  tag: {
    position: "absolute",
    padding: "2em 1.5em 2.5em 1.5em",
    top: "-10%",
    right: "50px",
    backgroundColor: theme.palette.secondary.dark,
    zIndex: 9999
  },
  tagText: {
    color: theme.palette.background.default,
    fontSize: "1em",
    letterSpacing: ".5em",
    transition: "transform .1s ease-in-out"
  },
  error: {
    color: "red"
  },
  focusHighlight: {
    backgroundColor: "transparent"
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.secondary.main
    }
  },
  notchedOutline: {},
  card: {
    height: 550,
    maxWidth: 345,
    padding: "2em",
    margin: "0 2em 2em 2em",
    position: "relative",
    borderRadius: 0
  },
  cardHeader: {
    height: "100px"
  },
  cardContent: {
    height: 400,
    position: "relative"
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em"
  },
  textField: {
    fontSize: "1em"
  },
  noLeftBorder: {
    borderLeft: "unset"
  },
  margin: {
    margin: "1em 0"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em"
  },
  button: {
    color: "white",
    borderRadius: 0,
    padding: "1em"
  },
  flipButton: {
    justifyContent: "space-between",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  },
  icon: {
    transition: "transform .1s ease-in-out"
  },
  rotated: {
    transform: "rotate(180deg)"
  },
  rotatedY: {
    transform: "rotateY(180deg)"
  }
});

Landing.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Landing);
