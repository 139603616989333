import React from "react";
import { functions, store } from "../../../firebase/firebase";

import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";

// MUI Colors
import { amber, blueGrey, deepOrange, green } from "@material-ui/core/colors";

// MUI Icons
import { AccountCircle, Clear, Edit } from "@material-ui/icons";

// Util
import { find } from "lodash";

const UserNew = ({
  auth,
  groupId,
  group,
  user,
  profile,
  setDeleteUser,
  setWarnDelete,
  password,
  handleEditUsers
}) => {
  const { id, first, last, status } = user;

  const [updating, setUpdating] = React.useState(false);

  const handleInviteUser = user => {
    setUpdating(true);
    const createUser = functions.httpsCallable("createUserDev");
    createUser({
      email: user.email,
      first: user.first,
      last: user.last,
      apiKey: user.apiKey,
      roleObject: user.roleObject,
      whitelabel: group.whitelabel ? group.whitelabel : {},
      permissionsAccess: {
        ...user.permissionsAccess
      },
      parent: auth.uid,
      parentGroup: groupId,
      password
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        console.log(e);
      });

    store
      .doc(`users/${auth.uid}/groups/${groupId}`)
      .get()
      .then(doc => {
        let users = doc.data().users;
        let updatedUser = {
          ...find(users, ["id", user.id]),
          status: "invited"
        };
        let filteredUsers = users.filter(u => u.id !== user.id);

        store
          .doc(`users/${auth.uid}/groups/${groupId}`)
          .update({
            users: [...filteredUsers, updatedUser]
          })
          .then(res => setUpdating(false))
          .catch(e => setUpdating(false));
      })
      .catch(e => setUpdating(false));
  };

  const clearUser = () => {
    setUpdating(true);
    store
      .doc(`users/${auth.uid}/groups/${groupId}`)
      .get()
      .then(doc => {
        let users = doc.data().users;

        let filteredUsers = users.filter(u => u.email !== user.email);

        store
          .doc(`users/${auth.uid}/groups/${groupId}`)
          .update({
            users: filteredUsers
          })
          .then(res => {
            setUpdating(false);
          })
          .catch(e => setUpdating(false));
      })
      .catch(e => setUpdating(false));
  };

  // Delete User
  const handleDeleteWarning = () => {
    setDeleteUser({
      name: `${user.first} ${user.last}`,
      email: user.email,
      api: user.apiKey
    });
    setWarnDelete(true);
  };

  return (
    <ListItem
      key={id}
      style={{ listStyle: "none", display: "flex", flexWrap: "wrap" }}
    >
      <ListItemAvatar>
        <Avatar
          color="secondary"
          style={{
            background:
              status === "needsInvite"
                ? deepOrange[500]
                : status === "invited"
                ? amber[500]
                : status === "accepted"
                ? green[500]
                : blueGrey[500]
          }}
        >
          <AccountCircle />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${last.toUpperCase()}, ${first}`}
        secondary={user.email}
      />
      {user.apiKey !== profile.api && status === "invited" && (
        <IconButton
          aria-label="invite user"
          onClick={() => handleDeleteWarning()}
        >
          <Clear />
        </IconButton>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%"
        }}
      >
        {status === "needsInvite" && (
          <>
            <IconButton onClick={() => clearUser()}>
              <Clear />
            </IconButton>
            <IconButton onClick={() => handleEditUsers(user)}>
              <Edit />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
          </>
        )}

        {user.apiKey !== profile.api && status !== "invited" && (
          <Button
            color="secondary"
            edge="end"
            aria-label="invite user"
            onClick={() => handleInviteUser(user)}
            disabled={status === "accepted" || status === "invited"}
          >
            {updating ? (
              <CircularProgress size={20} />
            ) : status === "needsInvite" ? (
              "Invite"
            ) : status === "accepted" ? (
              "Accepted"
            ) : status === "invited" ? (
              "Request Sent"
            ) : (
              "Invite"
            )}
          </Button>
        )}
      </div>
    </ListItem>
  );
};

export default UserNew;
