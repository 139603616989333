import React, { Component } from "react";

import { connect } from "react-redux";

import LoginCard from "../components/Landing/LoginCard";
import { push } from "connected-react-router";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { LANDING } from "../routes";

class Login extends Component {
  state = {
    open: false
  };

  render() {
    const { auth, reroute } = this.props;
    if (isLoaded(auth) && !isEmpty(auth)) reroute(LANDING);
    return (
      <div>
        <LoginCard />
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: state.firebase.auth
  }),
  dispatch => ({
    reroute: route => dispatch(push(route))
  })
)(Login);
