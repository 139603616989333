import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  clients: [],
  site: {}
};

function selectionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_SELECTION_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    default:
      return state;
  }
}

export default selectionReducer;
